import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import CreateCollectionModal from "./CreateCollectionModal";
import {
  CalendarIcon,
  VideoCameraIcon,
  DocumentTextIcon,
  ShareIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import amplitude from "amplitude-js";

const Collections = () => {
  const [collections, setCollections] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreateCollectionModal, setShowCreateCollectionModal] =
    useState(false);
  const [user, setUser] = useState(null);
  const [emptyState, setEmptyState] = useState(false);

  console.log("user", user);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        console.log("whats user", user);
        if (!user.orgID) {
          setEmptyState(true);
          setLoading(false);
          return;
        }

        const response = await fetch("https://backend.scribbl.co/collections", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length === 0) {
            setEmptyState(true);
            setLoading(false);
          }

          setCollections(data);
        } else {
          console.error("Error fetching collections");
        }
      } catch (error) {
        console.error("Error:", error);
      }

      console.log("setting laoding to false");
    };
    if (user) {
      fetchCollections();
    }
  }, [user]);

  useEffect(() => {
    if (collections && user && user.email) {
      setLoading(false);
      amplitude.getInstance().logEvent("Viewed Collections Page");
    }
  }, [collections, user]);

  const findOwner = (collection) => {
    return collection.usersAccess.find((access) => access.accessType === 1); // Assuming 1 signifies owner
  };

  // Utility function to format the access list
  const formatAccessList = (usersAccess) => {
    const maxVisibleUsers = 5;
    const visibleUsers = usersAccess
      .slice(0, maxVisibleUsers)
      .map((access) => access.User.name || access.User.email);
    const remainingCount = usersAccess.length - maxVisibleUsers;

    return {
      visibleUsers: visibleUsers.join(", "),
      remainingCount,
    };
  };

  return (
    <Navbar loading={loading} setUser={setUser}>
      {showCreateCollectionModal && (
        <CreateCollectionModal
          user={user}
          setUser={setUser}
          closeModal={() => setShowCreateCollectionModal(false)}
        />
      )}
      {emptyState ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <h1 className="text-4xl font-semibold text-gray-800 mb-2">
            Welcome to Collections
          </h1>
          <p className="text-xl mt-4 max-w-[60vw] text-gray-600 mb-6 text-center px-4">
            Group meetings in collections and share them with your team. Let
            Scribbl work for you and pull out AI insights about your meetings or
            answer any questions you might have
          </p>
          <button
            onClick={() => setShowCreateCollectionModal(true)}
            className="mt-4 bg-brand-turq hover:bg-brand-green-lighter2 text-white font-bold py-2 px-6 rounded-lg transition duration-150 ease-in-out"
          >
            Create Your First Collection
          </button>
          <img
            src="/collections-demo.png"
            alt="Collection screenshot"
            className="mt-5 w-full max-w-3xl rounded-lg shadow-xl"
          />
        </div>
      ) : (
        <div className="container mx-auto p-4">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-brand-neutral-dark">
              Collections
            </h1>
            <button
              onClick={() => setShowCreateCollectionModal(true)}
              className="bg-brand-turq hover:bg-brand-green-lighter2 text-white font-bold py-2 px-6 rounded-lg transition duration-150 ease-in-out"
            >
              Create Collection
            </button>
          </div>

          <div className="max-h-screen overflow-auto pb-[100px]">
            {collections && collections.length > 0 ? (
              <ul className="space-y-4">
                {user &&
                  collections.map((collection) => {
                    const ownerAccess = findOwner(collection);
                    console.log(ownerAccess);
                    const isShared =
                      ownerAccess && ownerAccess.userID !== user.ID;
                    const { visibleUsers, remainingCount } = formatAccessList(
                      collection.usersAccess
                    );

                    return (
                      <li
                        key={collection.ID}
                        className="bg-white shadow-md hover:shadow-lg transition-shadow duration-150 ease-in-out rounded-lg overflow-hidden"
                      >
                        <Link
                          to={`/collection/${collection.uuid}`}
                          className="block p-6"
                        >
                          <div className="flex items-stretch">
                            <div className="">
                              <h3 className="text-lg font-semibold text-brand-neutral-dark mb-2">
                                {collection.title}
                              </h3>
                              <p className="text-sm text-brand-gray mb-4">
                                {collection.description}
                              </p>

                              <div className="flex items-center justify-between text-brand-gray-lighter1">
                                <span className="flex items-center">
                                  {collection.enhancedRecordings.some(
                                    (rec) => rec.containsVideo
                                  ) ? (
                                    <VideoCameraIcon
                                      className="h-6 w-6 text-brand-turq mr-1"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <DocumentTextIcon
                                      className="h-6 w-6 text-brand-gray mr-1"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="text-sm">
                                    {collection.enhancedRecordings.length}{" "}
                                    meetings
                                  </span>
                                </span>
                              </div>
                              <div className="mt-2 text-brand-gray-lighter1 text-sm">
                                <CalendarIcon
                                  className="h-5 w-5 text-brand-gray mr-1 inline"
                                  aria-hidden="true"
                                />
                                <span>
                                  Created on{" "}
                                  {moment(collection.CreatedAt).format(
                                    "MMM D, YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="flex items-center text-sm mt-2">
                                <UsersIcon className="h-5 w-5 text-brand-turq mr-2" />
                                <span>
                                  {visibleUsers}
                                  {remainingCount > 0 &&
                                    ` +${remainingCount} more`}
                                </span>
                              </div>

                              {isShared && (
                                <div className="mt-7 flex items-center text-brand-gray text-sm">
                                  <ShareIcon
                                    className="h-4 w-4 text-brand-turq mr-1"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    Shared by{" "}
                                    {ownerAccess.User.name ||
                                      ownerAccess.User.email}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              collections && (
                <div className="text-center text-brand-gray">
                  No collections available.
                </div>
              )
            )}
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default Collections;
