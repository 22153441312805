import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ClipboardCopyIcon,
  UserIcon,
  CheckIcon,
  LockClosedIcon,
} from "@heroicons/react/outline";

import amplitude from "amplitude-js";

const ShareModal = ({ isOpen, onClose, user, collectionUUID }) => {
  const [teamMembers, setTeamMembers] = useState([]);

  const [selectedMembers, setSelectedMembers] = useState(new Set());
  const shareableLink = `${window.location.protocol}//${window.location.host}/collection/${collectionUUID}`; // Updated to include the collection UUID
  const [copySuccess, setCopySuccess] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  console.log("who's the user", user);

  // Extracted sorting logic for reuse
  const sortMembers = (members, currentUserID) => {
    return members.sort((a, b) =>
      a.user.ID === currentUserID ? -1 : b.user.ID === currentUserID ? 1 : 0
    );
  };

  const toggleMemberSelection = async (member, currentAccess) => {
    const updatedAccess = !currentAccess; // Toggle the current access

    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userAccesses: [
              {
                user: member,
                shouldHaveAccess: updatedAccess,
              },
            ],
          }),
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to update access");

      const updatedMembers = await response.json(); // Assuming this returns the updated list
      // Apply the sorting logic after the update
      const sortedUpdatedMembers = sortMembers(updatedMembers, user.ID);
      setTeamMembers(sortedUpdatedMembers);
      amplitude
        .getInstance()
        .logEvent("Collection Member Access Updated", {
          gaveAccess: updatedAccess,
        });
    } catch (error) {
      console.error("Error updating member access:", error);
    }
  };

  const selectAllMembers = async () => {
    // Determine the new desired access state based on current selection
    const selectAll = selectedMembers.size < teamMembers.length;

    try {
      // Map through all teamMembers to create the payload for updating access
      const userAccessUpdates = teamMembers.map((member) => ({
        user: member.user, // Ensure this matches your expected backend payload
        shouldHaveAccess: selectAll || member.user.ID === user.ID, // Select all or keep the user always selected
      }));

      // Call the backend to update access for all members
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userAccesses: userAccessUpdates }),
          credentials: "include",
        }
      );

      if (!response.ok)
        throw new Error("Failed to update access for all members");

      const updatedMembers = await response.json();
      // Apply the sorting logic and update the teamMembers state
      const sortedUpdatedMembers = sortMembers(updatedMembers, user.ID);
      setTeamMembers(sortedUpdatedMembers);
      // Update selectedMembers based on the action taken
      if (selectAll) {
        setSelectedMembers(
          new Set(teamMembers.map((member) => member.user.ID))
        );
      } else {
        setSelectedMembers(new Set([user.ID])); // Keep only the current user selected
      }
    } catch (error) {
      console.error("Error updating access for all members:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000); // Change icon back after 2 seconds
    });
    amplitude.getInstance().logEvent("Collection Link Copied");
  };

  useEffect(() => {
    const getOrgMembers = async () => {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();

        // Move the current user to the top of the list
        const sortedData = data.sort((a, b) =>
          a.user.ID === user.ID ? -1 : b.user.ID === user.ID ? 1 : 0
        );

        const currentUserAccess = data.find(
          (member) => member.user.ID === user.ID
        );
        setIsOwner(currentUserAccess && currentUserAccess.accessType === 1);

        setTeamMembers(sortedData);
      } else {
        console.error("Failed to fetch org members");
      }
    };
    getOrgMembers();
  }, [user.ID]);

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child className="sm:w-[80vw] w-[50vw] max-w-[760px] flex justify-center">
              {isOwner ? (
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Share Collection
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      Invite team members to view this collection by selecting
                      their names from the list below and sharing the generated
                      link with them. Only selected members will have access.
                    </p>
                  </div>

                  <div className="mt-4">
                    <strong className="text-sm text-gray-500">
                      The users selected below will have this collection listed
                      in their collections.
                    </strong>
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="text-md font-semibold text-gray-700">
                        Team Members:
                      </h4>
                      <button
                        onClick={selectAllMembers}
                        className="text-sm text-brand-turq hover:underline"
                      >
                        Select All
                      </button>
                    </div>
                    <div className="overflow-y-auto h-40 border border-gray-200 rounded-md p-2">
                      {teamMembers.map((member) => (
                        <div
                          key={member.user.ID}
                          className="flex items-center justify-between py-1"
                        >
                          <span className="flex items-center text-gray-700">
                            {member.user.ID === user.ID && (
                              <UserIcon className="h-4 w-4 mr-2 text-brand-turq" />
                            )}
                            {`${member.user.name} (${member.user.email})`}
                            {member.user.ID === user.ID && (
                              <span className="ml-2 text-sm text-brand-turq">
                                (You)
                              </span>
                            )}
                          </span>
                          {member.user.ID !== user.ID && (
                            <input
                              type="checkbox"
                              checked={member.shouldHaveAccess}
                              onChange={() =>
                                toggleMemberSelection(
                                  member.user,
                                  member.shouldHaveAccess
                                )
                              }
                              className="rounded border-gray-300 text-brand-turq shadow-sm focus:border-brand-turq focus:ring focus:ring-brand-turq focus:ring-opacity-50"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="mt-4">
                      <h4 className="text-md font-semibold text-gray-700">
                        Shareable Link:
                      </h4>
                      <div className="flex mt-2 items-center space-x-2 bg-gray-100 p-2 rounded">
                        <input
                          type="text"
                          readOnly
                          value={shareableLink}
                          className="flex-1 bg-transparent text-gray-700 focus:outline-none"
                        />
                        <button
                          onClick={copyToClipboard}
                          className="inline-flex items-center p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                          {copySuccess ? (
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ClipboardCopyIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-brand-turq px-4 py-2 text-sm font-medium text-white hover:bg-brand-green-lighter1 focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-turq focus-visible:ring-offset-2"
                        onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              ) : (
                <div className="bg-white text-center h-[400px] w-[600px] flex flex-col items-center justify-center">
                  <LockClosedIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-lg font-medium text-gray-900">
                    Access Restricted
                  </h3>
                  <p className="mt-4 text-sm text-gray-500">
                    Only the owner of this collection can modify share settings.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-brand-turq px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-brand-turq focus:ring-offset-2"
                      onClick={onClose}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareModal;
