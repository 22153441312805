import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import AIChat from "../AIChat";
import ShareModal from "./ShareModal"; // Adjust the import path as necessary
import moment from "moment";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ShareIcon,
  RefreshIcon,
  CheckIcon,
} from "@heroicons/react/outline";

import amplitude from "amplitude-js";

const Collection = () => {
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Collection");

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { collectionUUID } = useParams();
  console.log(collectionUUID, collection);

  const headerRef = useRef(null);

  const [isCollectionProcessing, setIsCollectionProcessing] = useState(false);
  const pollingRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (collection && user && user.email) {
      setLoading(false);
      amplitude.getInstance().logEvent("Viewed Individual Collection Page");
    }
  }, [collection, user]);

  const [settingsSaved, setSettingsSaved] = useState(false);

  useEffect(() => {
    if (settingsSaved) {
      // Stop the bouncing animation after 5 seconds
      const timer = setTimeout(() => setSettingsSaved(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [settingsSaved]);

  const checkCollectionReady = useCallback(async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (!data.isProcessing) {
          setIsCollectionProcessing(false); // Stop loading UI
          clearInterval(pollingRef.current); // Stop polling

          if (data.tiles) {
            data.tiles = JSON.parse(data.tiles);
          }
          setCollection(data); // Update collection data
        }
      } else {
        console.error("Failed to fetch collection details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [collectionUUID]);

  useEffect(() => {
    if (isCollectionProcessing) {
      // Start polling every 30 seconds (30000 milliseconds)
      pollingRef.current = setInterval(checkCollectionReady, 5000);
    }

    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, [isCollectionProcessing, checkCollectionReady]);

  const fetchCollectionDetails = useCallback(async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();

        if (data.isProcessing) {
          setIsCollectionProcessing(true);
        } else {
          setIsCollectionProcessing(false);
        }

        // Assuming 'tiles' is a JSON string, parse it into an object
        if (data.tiles) {
          data.tiles = JSON.parse(data.tiles);
        }

        setCollection(data);
      } else {
        console.error("Error fetching collection details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [collectionUUID]); // dependency array includes collectionUUID

  useEffect(() => {
    fetchCollectionDetails();
  }, [collectionUUID, fetchCollectionDetails]); // dependency array includes fetchCollectionDetails

  const CollectionLoadingState = () => {
    const [loadingMessage, setLoadingMessage] = useState(
      "Preparing your collection..."
    );
    const messages = [
      "Gathering meeting insights...",
      "Analyzing discussion topics...",
      "Generating timeline events...",
      "Creating AI summaries...",
      "Almost there...",
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        setLoadingMessage((prev) => {
          const currentIndex = messages.indexOf(prev);
          return currentIndex < messages.length - 1
            ? messages[currentIndex + 1]
            : messages[messages.length - 1];
        });
      }, 5000); // Change messages every 30 seconds

      return () => clearInterval(interval);
    }, []);

    return (
      <div className="flex mt-10 flex-col justify-center items-center h-full">
        <div className="flex h-full justify-center flex-col items-center text-center space-y-4">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-brand-turq"></div>
          <p className="w-full text-lg text-gray-800">{loadingMessage}</p>
        </div>
      </div>
    );
  };

  const CollectionContent = () => {
    const [showFullTimeline, setShowFullTimeline] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const timelineRef = useRef(null);
    const aiSummaryRef = useRef(null);

    const handleFeedbackClick = (type) => {
      setFeedbackGiven(type);
    };
    const timelineData = collection?.tiles?.timelineTile;
    const aiTiles = collection?.tiles?.aiTiles;
    const aiSummary = collection?.tiles?.aiSummary;

    const [feedbackGiven, setFeedbackGiven] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false); // New state

    // Adjust the height of the timeline tile to match AI Summary or its full content
    const adjustTimelineHeight = () => {
      if (!timelineRef.current || !aiSummaryRef.current) return;

      // If showing the full timeline, remove the maxHeight restriction
      if (showFullTimeline) {
        timelineRef.current.style.maxHeight = "none";
      } else {
        // Match the AI Summary tile's height initially
        const aiSummaryHeight = aiSummaryRef.current.offsetHeight;
        timelineRef.current.style.maxHeight = `${aiSummaryHeight}px`;
      }

      // Update the overflow status based on the full content height
      setIsOverflowing(
        timelineRef.current.scrollHeight > timelineRef.current.clientHeight
      );
    };

    useEffect(() => {
      const checkOverflowAndAdjustHeight = () => {
        if (!timelineRef.current || !aiSummaryRef.current) return;

        const aiSummaryHeight = aiSummaryRef.current.offsetHeight;
        timelineRef.current.style.maxHeight = showFullTimeline
          ? "none"
          : `${aiSummaryHeight}px`;

        const isContentOverflowing =
          timelineRef.current.scrollHeight > aiSummaryHeight;
        setIsOverflowing(isContentOverflowing);
      };

      checkOverflowAndAdjustHeight();

      // Attach the resize event listener to handle dynamic window size changes.
      window.addEventListener("resize", checkOverflowAndAdjustHeight);
      return () =>
        window.removeEventListener("resize", checkOverflowAndAdjustHeight);
    }, [showFullTimeline, collection]); // Re-run when these dependencies change

    useEffect(() => {
      // Ensure the timeline height is adjusted when the window resizes
      window.addEventListener("resize", adjustTimelineHeight);
      return () => window.removeEventListener("resize", adjustTimelineHeight);
    }, []);

    const toggleShowFullTimeline = () => {
      setShowFullTimeline(!showFullTimeline);
    };

    if (isCollectionProcessing) {
      return <CollectionLoadingState />;
    }

    return (
      <div className="p-4">
        <div className="grid grid-cols-3 gap-4">
          {/* Timeline Tile */}
          <div className={showFullTimeline ? "col-span-3" : `col-span-1`}>
            <div className="bg-white rounded-lg shadow p-4">
              <div
                ref={timelineRef}
                className={`bg-white rounded-lg shadow p-4 ${
                  showFullTimeline ? "" : " overflow-hidden"
                }`}
              >
                <h2 className="font-semibold text-lg text-gray-800 mb-4">
                  Timeline of Meetings
                </h2>
                <div className="flex">
                  {/* Continuous Line Section */}
                  <div className="w-0.5 bg-gray-300 my-2 mx-4"></div>

                  {/* Timeline Section */}
                  <div className="flex-grow">
                    {timelineData && timelineData.length > 0 ? (
                      timelineData.map((item, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-[100px_auto] items-center mb-4 gap-4"
                        >
                          {/* Date */}
                          <p className="text-sm font-medium text-gray-500 justify-self-end">
                            {item.date}
                          </p>

                          {/* Title/Description */}
                          <div>
                            <h3 className="text-md font-semibold text-gray-800">
                              {item.title}
                            </h3>
                            <p className="text-sm text-gray-600">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-600">
                        No timeline data available.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isOverflowing && !showFullTimeline && (
                <button
                  onClick={toggleShowFullTimeline}
                  className="mt-4 text-blue-500"
                >
                  Expand
                </button>
              )}
              {showFullTimeline && (
                <button
                  onClick={toggleShowFullTimeline}
                  className="mt-4 text-blue-500"
                >
                  Collapse
                </button>
              )}
            </div>
          </div>

          {/* AI summary Section */}
          {aiSummary && (
            <div
              className={`col-span-${
                showFullTimeline ? "3" : "2"
              } bg-white rounded-lg shadow p-4`}
            >
              <div
                ref={aiSummaryRef}
                className="bg-white rounded-lg shadow p-4"
              >
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  className="markdown-style"
                >
                  {aiSummary}
                </Markdown>
              </div>
            </div>
          )}

          {/* AI Tiles Section */}
          {aiTiles && aiTiles.length > 0 && (
            <div className="md:col-span-3 grid grid-cols-2 gap-4">
              {aiTiles.map((tile, index) => (
                <div key={index} className="bg-white rounded-lg shadow p-4">
                  <h3 className="font-semibold text-lg text-gray-800 mb-2">
                    {tile.tileTitle}
                  </h3>
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    className="markdown-style"
                  >
                    {tile.tileContent}
                  </Markdown>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Feedback tile */}
        {feedbackSubmitted ? (
          <div className="md:col-span-4 bg-white rounded-lg shadow p-4 mt-4">
            <div
              className="p-6 bg-white rounded-lg shadow space-y-4 flex flex-col items-center justify-center"
              style={{ minHeight: "300px", backgroundColor: "#F7FAFC" }}
            >
              <div className="animate-pulse">
                <CheckIcon className="h-12 w-12 text-green-500" />
              </div>
              <p className="text-lg font-semibold text-gray-700">
                Thank you for your feedback!
              </p>
            </div>
          </div>
        ) : (
          <div className="md:col-span-4 bg-white rounded-lg shadow p-4 mt-4">
            <div
              className="p-6 bg-white rounded-lg shadow space-y-4 flex flex-col items-center justify-center"
              style={{ minHeight: "300px", backgroundColor: "#F7FAFC" }}
            >
              <h2 className="text-xl font-semibold text-gray-900">Feedback</h2>
              <p className="text-gray-700">What do you think of Collections?</p>
              {!feedbackGiven && (
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleFeedbackClick("positive")}
                    className="flex items-center justify-center p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-150"
                  >
                    <ThumbUpIcon className="h-6 w-6 text-gray-600" />
                  </button>
                  <button
                    onClick={() => handleFeedbackClick("negative")}
                    className="flex items-center justify-center p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-150"
                  >
                    <ThumbDownIcon className="h-6 w-6 text-gray-600" />
                  </button>
                </div>
              )}
              {feedbackGiven && (
                <>
                  <textarea
                    className="form-textarea mt-4 w-full max-w-md h-24 p-2 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
                    placeholder="We'd love to hear more about your thoughts..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                    style={{ backgroundColor: "#EDF2F7" }}
                  ></textarea>
                  <button
                    onClick={async () => {
                      setIsSubmitting(true);
                      console.log("feedback", feedbackGiven, feedbackText);

                      // Simulate API call delay and successful submission
                      await fetch(
                        `https://backend.scribbl.co/collections/${collection.uuid}/feedback`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            feedback: `Feedback sentiment: ${feedbackGiven}\nFeedback: ${feedbackText}`,
                          }),
                        }
                      );

                      setIsSubmitting(false);
                      setFeedbackSubmitted(true); // Indicate feedback was submitted

                      // Wait for 2 seconds before resetting the component to its initial state
                      setTimeout(() => {
                        setFeedbackGiven(false);
                        setFeedbackSubmitted(false);
                      }, 2000);
                    }}
                    disabled={isSubmitting}
                    className="mt-4 bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit Feedback
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const updateAIChat = (newAIChat) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      aiChat: JSON.stringify(newAIChat), // Assuming newAIChat is the updated chat array
    }));
  };

  // Component or JSX for Chat tab content
  const ChatContent = () => {
    return (
      <div className="flex-1 overflow-hidden">
        <AIChat
          isCollection={true}
          uuid={collection.uuid}
          userEmail={userEmail}
          updateAIChat={updateAIChat}
          initialAIChat={collection.aiChat}
          isProUser={user.subscription && user.subscription.accountType !== 0}
        />
      </div>
    );
  };

  const SettingsContent = () => {
    // Original collection details
    const [originalTitle, setOriginalTitle] = useState(collection?.title || "");
    const [originalDescription, setOriginalDescription] = useState(
      collection?.description || ""
    );
    const [originalPurpose, setOriginalPurpose] = useState(
      collection?.purpose || ""
    );

    // Edited values
    const [editTitle, setEditTitle] = useState(collection?.title || "");
    const [editDescription, setEditDescription] = useState(
      collection?.description || ""
    );
    const [editPurpose, setEditPurpose] = useState(collection?.purpose || "");

    // Check if the form has been changed
    const hasChanges =
      editTitle !== originalTitle ||
      editDescription !== originalDescription ||
      editPurpose !== originalPurpose;

    const handleUpdateCollection = async (e) => {
      e.preventDefault(); // Prevent form from submitting normally

      const response = await fetch(
        `https://backend.scribbl.co/collections/${collection.uuid}/details`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: editTitle,
            description: editDescription,
            purpose: editPurpose,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        data.enhancedRecordings = collection.enhancedRecordings;
        setOriginalTitle(data.title);
        setOriginalDescription(data.description);
        setOriginalPurpose(data.purpose);

        if (data.tiles) {
          data.tiles = JSON.parse(data.tiles);
        }

        setCollection(data);
        setSettingsSaved(true);
      } else {
        console.error("Failed to update collection.");
      }
    };

    return (
      <div className="px-6 py-4">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Collection Settings
        </h2>
        <p className="text-sm text-gray-500">
          The settings below will be used while creating the AI content for your
          collection. Please give as much detail about the information you're
          interested in, so that we can produce the best data for you.
        </p>
        <form onSubmit={handleUpdateCollection}>
          {/* Collection Title */}
          <div className="mt-4 mb-6">
            <label
              htmlFor="collectionTitle"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Collection Title
            </label>
            <p className="mt-2 text-sm text-gray-500">
              Give your collection a concise and descriptive title.
            </p>
            <input
              type="text"
              id="collectionTitle"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-turq focus:border-brand-turq block w-full p-2.5"
            />
          </div>

          {/* Collection Description */}
          <div className="mb-6">
            <label
              htmlFor="collectionDescription"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Collection Description
            </label>
            <p className="mt-2 text-sm text-gray-500">
              Explain what this collection is about and the kind of data it
              represents.
            </p>
            <textarea
              id="collectionDescription"
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-turq focus:border-brand-turq block w-full p-2.5"
              rows="4"
            ></textarea>
          </div>

          {/* Collection Interests */}
          <div className="mb-6">
            <label
              htmlFor="collectionInterests"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Collection Interests
            </label>
            <p className="mt-2 text-sm text-gray-500">
              Detail the key insights, analysis, or information you hope to gain
              from this collection.
            </p>
            <textarea
              id="collectionInterests"
              value={editPurpose}
              onChange={(e) => setEditPurpose(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-turq focus:border-brand-turq block w-full p-2.5"
              rows="3"
            ></textarea>
          </div>

          <button
            type="submit"
            disabled={!hasChanges}
            className={`text-white ${
              hasChanges
                ? "bg-brand-turq hover:bg-brand-green-lighter1"
                : "bg-gray-300"
            } focus:ring-4 focus:outline-none focus:ring-brand-turq font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
          >
            Save Settings
          </button>
        </form>
      </div>
    );
  };

  // Function to render content based on the selected tab
  const renderContent = () => {
    switch (selectedTab) {
      case "Overview":
        return <CollectionContent />;
      case "Chat":
        return <ChatContent />;
      case "Settings":
        return <SettingsContent />;
      default:
        return <CollectionContent />;
    }
  };

  console.log(collection);

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      setLoading={setLoading}
      loading={loading}
    >
      <div className="flex w-full h-screen">
        {/* Left Panel with Title and Nav */}
        <div className="w-[75%] bg-gray-50">
          <div ref={headerRef} className="bg-white">
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-2xl p-5">
                  {collection && collection.title
                    ? collection.title
                    : "Unnamed Collection"}
                </h1>

                <p className="cursor-default text-gray-600 ml-5 mb-6 overflow-ellipsis overflow-hidden whitespace-nowrap max-w-xl">
                  {collection && collection.description
                    ? collection.description
                    : "No description provided."}
                </p>
              </div>

              <button
                onClick={() => setIsShareModalOpen(true)}
                className="w-[200px] h-10 p-5 m-5 mr-5 bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out flex items-center"
                title="Share Collection"
              >
                <ShareIcon className="h-5 w-5 mr-2" />
                Share Collection
              </button>
            </div>
            <ShareModal
              isOpen={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
              user={user}
              collectionUUID={collectionUUID}
            />
            <div className="bg-white ml-5 mr-5">
              <div className="flex items-end justify-between  border-b">
                <div className="flex space-x-6">
                  {["Collection", "Chat", "Settings"].map((tab) => (
                    <div
                      key={tab}
                      className={`pb-3 cursor-pointer ${
                        selectedTab === tab
                          ? "text-brand-green border-b-2 border-brand-green"
                          : "text-gray-600 hover:text-brand-green"
                      }`}
                      onClick={() => setSelectedTab(tab)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
                <div className="flex pb-2 items-center">
                  {collection?.lastUpdated && (
                    <span className="text-sm text-gray-500">
                      Last updated:{" "}
                      {new Date(collection.lastUpdated).toLocaleString()}
                    </span>
                  )}
                  {collection?.eligibleForRefresh && (
                    <button
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={async () => {
                        try {
                          collection.eligibleForRefresh = false;
                          setCollection(collection);
                          setSelectedTab("Collection");
                          const response = await fetch(
                            `https://backend.scribbl.co/collections/${collectionUUID}/updateTiles`,
                            {
                              method: "POST",
                              credentials: "include",
                            }
                          );
                          if (response.ok) {
                            const data = await response.json();
                            if (data.isProcessing) {
                              setIsCollectionProcessing(true);
                            }
                            amplitude
                              .getInstance()
                              .logEvent("Refreshed Collection");
                          } else {
                            console.error("Failed to fetch collection details");
                          }
                        } catch (error) {
                          console.error("Error:", error);
                        }
                      }}
                      className={`refresh-button ml-1 relative flex items-center justify-center ${
                        settingsSaved ? "bounce" : ""
                      }`}
                      disabled={!collection?.eligibleForRefresh}
                    >
                      <RefreshIcon
                        className={`p-1 h-6 w-6 ${
                          isHovered ? "text-gray" : "text-gray"
                        } transition-colors duration-200`}
                      />

                      {/* Hover background square with lighter background and darker border */}
                      <div
                        className={`absolute inset-0 rounded-md ${
                          isHovered
                            ? "bg-[#a90b6a3] border border-gray-400"
                            : "bg-transparent"
                        } transition-all duration-200`}
                      ></div>

                      {/* Tooltip */}
                      {isHovered && (
                        <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap px-2 py-1 bg-black text-white text-xs rounded">
                          Update collection content
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Content Area */}
          <div
            className="overflow-y-auto p-3"
            style={{ maxHeight: "calc(100vh - 140px)" }}
          >
            {renderContent()}
          </div>
        </div>

        {/* Right Panel with Meetings List */}
        <div className="h-full w-[25%] text-xs overflow-y-auto bg-white  border-l">
          <div className="flex flex-col items-center p-4 border-b">
            <h2 className="mt-5 text-lg font-semibold">Meetings List</h2>
          </div>
          <div className="meetings-list overflow-y-auto">
            {collection &&
              collection.enhancedRecordings.map((meeting) => (
                <div
                  key={meeting.ID}
                  className="flex items-center p-4 bg-white rounded-lg shadow mb-4 hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                  onClick={() => {
                    window.open(`/meeting/${meeting.uuid}`, "_blank"); // Open in a new tab
                    amplitude
                      .getInstance()
                      .logEvent("Clicked on Collection Meeting");
                  }}
                >
                  <img
                    src={
                      meeting.coverThumbnail
                        ? meeting.coverThumbnail
                        : "/no-thumbnail.png"
                    }
                    alt="Meeting Thumbnail"
                    style={{
                      width: "120px",
                      height: "67px",
                      objectFit: "cover",
                    }}
                    className="mr-4 rounded"
                  />
                  <div className="flex-grow">
                    <h3 className="font-semibold text-gray-800">
                      {meeting.meetingTitle}
                    </h3>
                    <p className="mt-1 text-gray-600">
                      {moment(meeting.CreatedAt).format("MMM D, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Collection;
