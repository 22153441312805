import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import OnboardingJoinTeam from "./OnboardingJoinTeam";
import OnboardingCreateTeam from "./OnboardingCreateTeam";
import Confetti from "react-confetti";

const JoinOrCreateTeamModal = ({
  user,
  setUser,
  setOpen,
  open,
  giftCredits,
}) => {
  const JOIN_TEAM_STEP = "joinTeam";
  const CREATE_TEAM_STEP = "createTeam";
  const SUCCESS_STEP = "success";

  const [hasTeamsToJoin, setHasTeamsToJoin] = useState(true);
  const [usingEmailProvider, setUsingEmailProvider] = useState(false);
  const [currentStep, setCurrentStep] = useState(JOIN_TEAM_STEP);

  const giftCreditRef = useRef();
  const [confettiHeight, setConfettiHeight] = useState(0);
  const [confettiWidth, setConfettiWidth] = useState(0);

  const cancelButtonRef = useRef(null);

  function setOrg(org) {
    setCurrentStep(SUCCESS_STEP);
    if (org) {
      let userClone = structuredClone(user);
      userClone.org = org;
      setUser(userClone);
    }
  }

  useEffect(() => {
    setConfettiHeight(giftCreditRef.current?.offsetHeight);
    setConfettiWidth(giftCreditRef.current?.offsetWidth);
  }, [giftCreditRef.current]);

  useEffect(() => {
    if (currentStep === SUCCESS_STEP && !giftCredits) {
      setOpen(false);
    }
  }, [currentStep]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                ref={giftCreditRef}
              >
                <div>
                  {currentStep === JOIN_TEAM_STEP && (
                    <OnboardingJoinTeam
                      fromCollections={true}
                      email={user.email}
                      userObj={user}
                      setUser={setUser}
                      setHasTeamsToJoin={setHasTeamsToJoin}
                      hasTeamsToJoin={hasTeamsToJoin}
                      setUsingEmailProvider={setUsingEmailProvider}
                      usingEmailProvider={usingEmailProvider}
                      nextStep={setCurrentStep}
                      giftCredits={giftCredits}
                      disableSkip={true}
                      //onboardingMethod={onboardingMethod}
                    ></OnboardingJoinTeam>
                  )}
                  {currentStep === CREATE_TEAM_STEP && (
                    <OnboardingCreateTeam
                      nextStep={setCurrentStep}
                      email={user.email}
                      userObj={user}
                      hasTeamsToJoin={hasTeamsToJoin}
                      usingEmailProvider={usingEmailProvider}
                      setOrg={setOrg}
                      giftCredits={giftCredits}
                    ></OnboardingCreateTeam>
                  )}

                  {currentStep === SUCCESS_STEP && giftCredits && (
                    <div className="h-full w-full my-14">
                      <Confetti
                        width={confettiWidth}
                        height={confettiHeight}
                        recycle={false}
                        numberOfPieces={400}
                      />
                      <div className="text-center items-center text-lg text-gray-700 mt-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="orange"
                          className="w-20 h-20 mx-auto"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207 6.72 6.72 0 00.857-3.294z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="mt-5">
                          Congratulations! You've earned 15 additional credits.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default JoinOrCreateTeamModal;
