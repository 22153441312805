import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";

import amplitude from "amplitude-js";

const AddMeetingToCollectionModal = ({ isOpen, onClose, meetingId }) => {
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState(new Set());
  const [addSuccess, setAddSuccess] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch(`https://backend.scribbl.co/collections`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          const sortedData = [...data].sort((a, b) =>
            a.enhancedRecordings.some(
              (recording) => recording.ID === meetingId
            ) ===
            b.enhancedRecordings.some((recording) => recording.ID === meetingId)
              ? 0
              : a.enhancedRecordings.some(
                  (recording) => recording.ID === meetingId
                )
              ? 1
              : -1
          );
          setCollections(sortedData);
        } else {
          console.error("Failed to fetch collections");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isOpen) {
      fetchCollections();
      setAddSuccess(false); // Reset success state when modal is opened
    }
  }, [isOpen]);

  const toggleCollectionSelection = (collectionId) => {
    const updatedSelection = new Set(selectedCollections);
    if (updatedSelection.has(collectionId)) {
      updatedSelection.delete(collectionId);
    } else {
      updatedSelection.add(collectionId);
    }
    setSelectedCollections(updatedSelection);
  };

  const addMeetingToCollections = async () => {
    const url = `https://backend.scribbl.co/collections/addMeeting/${meetingId}`;
    try {
      const collectionIds = Array.from(selectedCollections);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(collectionIds),
        credentials: "include",
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to add meeting to collections: ${errorText}`);
      }
      setAddSuccess(true);
      setTimeout(() => {
        onClose(); // Close the modal on success
        setAddSuccess(false); // Optionally reset success indicator after a delay
      }, 600); // Adjust delay as needed
      amplitude.getInstance().logEvent("Meeting Added to Collection");
    } catch (error) {
      console.error("Error adding meeting to collections:", error);
    }
  };

  const isMeetingAlreadyAdded = (collection) => {
    return collection.enhancedRecordings.some(
      (recording) => recording.ID === meetingId
    );
  };

  const noCollectionsAvailable = collections.length === 0;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Meeting to Collection
                </Dialog.Title>
                <div className="mt-2">
                  {noCollectionsAvailable ? (
                    <div className="text-center p-12">
                      <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No Collections Available
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        It looks like you don't have any collections yet.
                        Collections are a great way to organize and visualize
                        your meetings and insights.
                      </p>
                      <div className="mt-6">
                        <button
                          onClick={() => window.open("/collections", "_blank")}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 "
                        >
                          Create Your First Collection
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">
                      Select one or more collections to add the meeting to.
                      Review each collection's details below.
                    </p>
                  )}
                </div>

                <div className="mt-4 max-h-60 overflow-y-auto">
                  {collections.map((collection) => (
                    <div
                      key={collection.ID}
                      className={`flex flex-col gap-2 p-2 border-b ${
                        isMeetingAlreadyAdded(collection) ? "opacity-50" : ""
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <input
                          id={`collection-${collection.ID}`}
                          type="checkbox"
                          checked={selectedCollections.has(collection.ID)}
                          onChange={() =>
                            toggleCollectionSelection(collection.ID)
                          }
                          className="h-4 w-4 text-brand-green border-gray-300 rounded focus:ring-brand-green"
                          disabled={isMeetingAlreadyAdded(collection)}
                        />
                        <label
                          htmlFor={`collection-${collection.ID}`}
                          className="ml-3 flex-1 text-sm font-medium text-gray-900"
                        >
                          {collection.title}
                        </label>
                        <span className="ml-3 text-sm text-gray-500 flex items-center">
                          <CalendarIcon
                            className="h-5 w-5 mr-1"
                            aria-hidden="true"
                          />
                          {new Date(collection.CreatedAt).toLocaleDateString()}
                        </span>
                      </div>
                      <p className="text-sm text-gray-600 ml-7">
                        {collection.description}
                        <br />
                        {collection.enhancedRecordings.length}{" "}
                        {collection.enhancedRecordings.length === 1
                          ? "meeting"
                          : "meetings"}
                        {isMeetingAlreadyAdded(collection) &&
                          " - Meeting already added"}
                      </p>
                    </div>
                  ))}
                </div>

                {collections && collections.length > 0 && (
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
                        addSuccess
                          ? "bg-brand-green-darker2"
                          : "bg-brand-green hover:bg-brand-green-darker2"
                      }`}
                      style={
                        selectedCollections.size === 0
                          ? { visibility: "hidden" }
                          : {}
                      }
                      onClick={addMeetingToCollections}
                      disabled={addSuccess || selectedCollections.size === 0}
                    >
                      {addSuccess ? (
                        <>
                          <CheckIcon
                            className="h-5 w-5 mr-2"
                            aria-hidden="true"
                          />
                          Added Successfully
                        </>
                      ) : (
                        `Add Meeting to ${selectedCollections.size} ${
                          selectedCollections.size === 1
                            ? "Collection"
                            : "Collections"
                        }`
                      )}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMeetingToCollectionModal;
