import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  BriefcaseIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import moment from "moment";
import JoinOrCreateTeamModal from "../JoinOrCreateTeamModal";
import amplitude from "amplitude-js";

const CreateCollectionModal = ({ closeModal, user, setUser }) => {
  const [step, setStep] = useState(0);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [userInterest, setUserInterest] = useState("");

  const [recordings, setRecordings] = useState([]);
  const [recordingsLoading, setRecordingsLoading] = useState(false);

  const [selectedMeetings, setSelectedMeetings] = useState(new Set());

  const [creatingCollection, setCreatingCollection] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const [joinTeamModalOpen, setJoinTeamModalOpen] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const fetchRecordings = async () => {
      setRecordingsLoading(true);
      try {
        const response = await fetch(
          `https://backend.scribbl.co/enhanced-recording?email=${user.email}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => b.enhancedRecordingID - a.enhancedRecordingID
          );
          setRecordings(sortedData);
          setRecordings(data);
          console.log(data);
        } else {
          console.error("Error fetching recordings");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setRecordingsLoading(false);
    };

    fetchRecordings();
  }, []);

  // Update useEffect to monitor changes to collectionTitle, collectionDescription, and userInterest
  useEffect(() => {
    const validateForm = () => {
      return (
        collectionTitle.trim() !== "" &&
        collectionDescription.trim() !== "" &&
        userInterest.trim() !== ""
      );
    };

    setIsFormValid(validateForm());
  }, [collectionTitle, collectionDescription, userInterest]);

  const navigate = useNavigate();

  const isPartOfOrg = user && (user.orgID || (user.org && user.org.ID !== 0));

  const handleCreateCollection = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          title: collectionTitle,
          description: collectionDescription,
          purpose: userInterest,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("did we get a respose", data);
        return data.uuid;
      } else {
        console.error("Failed to create collection");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectMeeting = (meetingId) => {
    setSelectedMeetings((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(meetingId)) {
        newSelected.delete(meetingId);
      } else {
        newSelected.add(meetingId);
      }
      return newSelected;
    });
  };

  const addMeetingsToCollection = async (collectionUUID) => {
    const selectedMeetingIds = Array.from(selectedMeetings);

    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ meetingIDs: selectedMeetingIds }),
        }
      );

      if (response.ok) {
      } else {
        console.error("Failed to add meetings to collection");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CloseButton = ({ onClick }) => (
    <button
      onClick={onClick}
      className="absolute top-4 right-4 text-brand-gray hover:text-brand-gray-darker2"
      aria-label="Close modal"
    >
      <svg
        className="w-6 h-6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  );

  return (
    <Transition.Root show={true} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-2 overflow-y-auto"
        onClose={closeModal}
      >
        <JoinOrCreateTeamModal
          giftCredits={false}
          open={joinTeamModalOpen}
          setOpen={setJoinTeamModalOpen}
          setUser={setUser}
          user={user}
        />
        {/* Modal content goes here, adjusted to handle different steps */}
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This container ensures the modal content is centered */}

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {isPartOfOrg ? (
              <div className="max-w-[800px] inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[80vw] max-h-[90vh]">
                {/* Different content based on `step` state */}
                {step === 0 && (
                  <div className="min-h-[50vh] inline-block align-middle bg-white rounded-lg px-6 py-8 text-center overflow-hidden shadow-xl transform transition-all w-full max-w-4xl">
                    <CloseButton onClick={closeModal} />

                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold text-gray-900 mb-4"
                    >
                      Choose a Collection Template
                    </Dialog.Title>
                    <p className="text-sm text-gray-500 mb-6">
                      Select a template to get started quickly with a predefined
                      structure, or choose a blank slate to customize from
                      scratch. These templates help organize your meetings and
                      insights for specific purposes.
                    </p>
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {/* Template: Client Collection */}
                      <div
                        className="flex flex-col items-center cursor-pointer p-4 border-2 border-gray-200 rounded-lg hover:shadow-lg transition-shadow duration-200"
                        onClick={() => {
                          setCollectionTitle(
                            `${
                              user.firstName ? `${user.firstName}'s ` : ""
                            }Client Collection for Client [Client Name]`
                          );

                          setCollectionDescription(
                            "In this collection, I'll curate all our interactions, milestones, and discussions related to the client, [Client Name]. It's a central hub for the team to quickly get up to speed on where we stand with [Client Name], ensuring we're aligned and responsive to their needs. It's about keeping our client relations sharp, proactive, and personal."
                          );

                          setUserInterest(
                            "I'm focusing on capturing the pulse of our client relationship—tracking project milestones, addressing any concerns promptly, and identifying opportunities to add value. It's all about fostering a thriving partnership."
                          );

                          setStep(1);
                          amplitude
                            .getInstance()
                            .logEvent("Create Collection Choose Template", {
                              template: "Client Collection",
                            });
                        }}
                      >
                        <BriefcaseIcon
                          className="w-12 h-12 text-brand-turq"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        />
                        <span className="mt-2 text-lg font-medium text-gray-900">
                          Client Collection
                        </span>
                        <p className="text-sm text-gray-500 mt-1 text-center">
                          Keep all the meetings for a client in one place.
                        </p>
                      </div>

                      {/* Template: Product Research */}
                      <div
                        className="flex flex-col items-center cursor-pointer p-4 border-2 border-gray-200 rounded-lg hover:shadow-lg transition-shadow duration-200"
                        onClick={() => {
                          setCollectionTitle(
                            `${
                              user.firstName ? `${user.firstName}'s ` : ""
                            }Product Research Collection`
                          );

                          setCollectionDescription(
                            "This collection is a gateway to uncovering deep insights from product research. It aggregates and analyzes feedback from user interviews to provide a rich foundation for data-driven product decisions. Designed to surface user needs and product opportunities, it's an essential tool for any product team aiming to innovate with confidence."
                          );

                          setUserInterest(
                            "Unearthing key user insights, identifying common challenges, capturing feedback trends, and highlighting opportunities for innovation. Aimed at informing strategic product development decisions."
                          );

                          setStep(1);
                          amplitude
                            .getInstance()
                            .logEvent("Create Collection Choose Template", {
                              template: "Product and Research Collection",
                            });
                        }}
                      >
                        <ClipboardCheckIcon
                          className="w-12 h-12 text-brand-turq"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        />
                        <span className="mt-2 text-lg font-medium text-gray-900">
                          Product Research
                        </span>
                        <p className="text-sm text-gray-500 mt-1 text-center">
                          Pull out insights from product interviews.
                        </p>
                      </div>
                      {/* Template: From Scratch */}
                      <div
                        className="flex flex-col items-center cursor-pointer p-4 border-2 border-gray-200 rounded-lg hover:shadow-lg transition-shadow duration-200"
                        onClick={() => {
                          setCollectionTitle("");
                          setCollectionDescription("");
                          setUserInterest("");
                          setStep(1);
                          amplitude
                            .getInstance()
                            .logEvent("Create Collection Choose Template", {
                              template: "From Scratch",
                            });
                        }}
                      >
                        <svg
                          className="w-12 h-12 text-brand-turq"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                        <span className="mt-2 text-lg font-medium text-gray-900">
                          From Scratch
                        </span>
                        <p className="text-sm text-gray-500 mt-1 text-center">
                          Start with a blank slate and define things yourself.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {step === 1 && (
                  <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-6">
                    <CloseButton onClick={closeModal} />

                    <h3 className="text-lg leading-6 font-medium text-brand-neutral-dark">
                      Create New Collection
                    </h3>
                    <div className="mt-4 text-brand-gray">
                      <p className="text-sm">
                        Provide details to help the AI understand the context of
                        your collection. This information will be used to
                        generate insights tailored to your needs.
                      </p>
                      <div className="mt-4">
                        <label
                          htmlFor="collectionTitle"
                          className="block text-sm font-medium text-brand-gray-darker2"
                        >
                          Collection Title
                        </label>
                        <input
                          type="text"
                          name="collectionTitle"
                          id="collectionTitle"
                          value={collectionTitle}
                          onChange={(e) => setCollectionTitle(e.target.value)}
                          placeholder="E.g., Project Alpha Review"
                          className="mt-1 w-full border border-brand-gray-lighter3 bg-brand-neutral-light p-2 rounded-md shadow-sm focus:border-brand-turq focus:ring focus:ring-brand-turq focus:ring-opacity-50"
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="collectionDescription"
                          className="block text-sm font-medium text-brand-gray-darker2"
                        >
                          Collection Description
                        </label>
                        <textarea
                          name="collectionDescription"
                          id="collectionDescription"
                          value={collectionDescription}
                          onChange={(e) =>
                            setCollectionDescription(e.target.value)
                          }
                          placeholder="Briefly describe the purpose of this collection"
                          className="mt-1 w-full border border-brand-gray-lighter3 bg-brand-neutral-light p-2 rounded-md shadow-sm focus:border-brand-turq focus:ring focus:ring-brand-turq focus:ring-opacity-50"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="userInterest"
                          className="block text-sm font-medium text-brand-gray-darker2"
                        >
                          Key Insights You're Seeking
                        </label>
                        <textarea
                          name="userInterest"
                          id="userInterest"
                          value={userInterest}
                          onChange={(e) => setUserInterest(e.target.value)}
                          placeholder="Describe what insights, analysis, or information you hope to gain from this collection"
                          className="mt-1 w-full border border-brand-gray-lighter3 bg-brand-neutral-light p-2 rounded-md shadow-sm focus:border-brand-turq focus:ring focus:ring-brand-turq focus:ring-opacity-50"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center gap-4">
                      <button
                        type="button"
                        className="flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-brand-gray bg-brand-neutral-light hover:bg-brand-gray-lighter3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                        onClick={() => setStep(step - 1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-brand-turq"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                      <button
                        type="button"
                        className={`flex-grow inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq ${
                          isFormValid
                            ? "bg-brand-turq text-white hover:bg-brand-green-lighter1"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        }`}
                        onClick={() => {
                          setStep(step + 1);
                          amplitude
                            .getInstance()
                            .logEvent("Create Collection Name and Description");
                        }}
                        disabled={!isFormValid}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-full max-w-[80vw] max-h-[95vh]">
                    <CloseButton onClick={closeModal} />

                    <div className="bg-white px-6 py-5">
                      <h3 className="text-2xl font-semibold text-center text-brand-neutral-dark">
                        Select Meetings for Your Collection
                      </h3>
                      <p className="mt-2 text-center text-sm text-brand-gray">
                        Highlight the meetings you want to analyze. Clicking
                        again deselects them.
                      </p>

                      <div
                        className={
                          recordingsLoading
                            ? ""
                            : "mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-y-auto"
                        }
                        style={{ maxHeight: "400px" }}
                      >
                        {recordingsLoading ? (
                          <div className="mt-10 flex justify-center items-center w-full h-full">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-turq"></div>
                          </div>
                        ) : recordings.length > 0 ? (
                          recordings.map((recording) => (
                            <div
                              key={recording.enhancedRecordingID}
                              className={`flex flex-col items-center justify-between p-4 rounded-lg transition duration-150 ease-in-out cursor-pointer shadow-md ${
                                selectedMeetings.has(
                                  recording.enhancedRecordingID
                                )
                                  ? "border-2 border-brand-green"
                                  : "hover:shadow-lg"
                              }`}
                              onClick={() =>
                                handleSelectMeeting(
                                  recording.enhancedRecordingID
                                )
                              }
                            >
                              <div className="w-full max-w-[300px] h-32 overflow-hidden rounded-lg mb-3">
                                <img
                                  src={
                                    recording.enhancedRecording.coverThumbnail
                                      ? recording.enhancedRecording
                                          .coverThumbnail
                                      : "/transcript-only-icon.svg"
                                  }
                                  alt="Meeting Thumbnail"
                                  className="w-full h-full object-cover"
                                />
                              </div>

                              <div className="text-center">
                                <h4 className="text-md font-medium text-brand-neutral-dark mb-1">
                                  {recording.enhancedRecording.meetingTitle}
                                </h4>
                                <p className="text-sm text-brand-gray-lighter1">
                                  {moment(recording.CreatedAt).format(
                                    "MMM D, YYYY"
                                  )}
                                </p>
                              </div>

                              <div
                                className={`w-6 h-6 mt-2 ${
                                  selectedMeetings.has(
                                    recording.enhancedRecordingID
                                  )
                                    ? "text-brand-turq"
                                    : "text-brand-gray-lighter1"
                                }`}
                              >
                                {selectedMeetings.has(
                                  recording.enhancedRecordingID
                                ) && <CheckIcon className="w-6 h-6" />}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="flex justify-center text-center col-span-full">
                            <p className="max-w-[300px] text-brand-gray">
                              No meetings available. <br></br>Use Scribbl's
                              chrome extension to start recording meetings
                              today.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-6 p-6 flex items-center gap-4">
                      <button
                        type="button"
                        className="flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-brand-gray bg-brand-neutral-light hover:bg-brand-gray-lighter3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                        onClick={() => setStep(step - 1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-brand-turq"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                      <button
                        type="button"
                        className="flex-grow inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-turq text-base font-medium text-white hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                        onClick={async () => {
                          amplitude
                            .getInstance()
                            .logEvent("Create Collection Select Meetings", {
                              meeting_count: selectedMeetings.size,
                            });
                          const startTime = Date.now();
                          setCreatingCollection(true);
                          try {
                            const collectionUUID =
                              await handleCreateCollection();

                            // Assuming addMeetingsToCollection is an async function. If it's not, adjust accordingly.
                            await addMeetingsToCollection(collectionUUID);

                            // Calculate the total elapsed time including both operations.
                            const elapsedTime = Date.now() - startTime;

                            // Ensure the loading spinner is shown for at least the minimum loading time.
                            const minLoadingTime = 1500; // Minimum loading time in milliseconds.
                            const additionalDelay = Math.max(
                              minLoadingTime - elapsedTime,
                              0
                            );

                            // Use the additional delay to ensure the spinner shows for the minimum time.
                            setTimeout(() => {
                              setCreatingCollection(false);
                              setCreateSuccess(true);

                              // After showing the success checkmark for a brief period, navigate to the new page.
                              setTimeout(() => {
                                navigate(`/collection/${collectionUUID}`);
                              }, 1000); // Adjust as needed for how long you want the checkmark to show
                            }, additionalDelay);
                          } catch (error) {
                            console.error("Error creating collection:", error);
                            setCreatingCollection(false);
                            // Optionally, handle the error (e.g., show an error message)
                          }
                        }}
                        disabled={creatingCollection} // Keep disabled while processing or on completion state
                      >
                        {creatingCollection ? (
                          // Loader icon
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : createSuccess ? (
                          // Check icon animation or static icon after loading and before navigating
                          // Animated check icon
                          <svg
                            className="checkmark-animation -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          "Finish Creating Collection"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="h-[400px] max-w-lg mx-auto inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <div className="bg-gradient-to-r from-brand-turq to-brand-green-lighter1 p-5 rounded-t-2xl flex items-center">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-white sm:mx-0 sm:h-12 sm:w-12">
                    <BriefcaseIcon
                      className="h-8 w-8 text-brand-turq"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="bg-white px-8 pt-6 pb-8 sm:p-10">
                  <h3 className="text-xl font-semibold text-gray-900 text-center sm:text-2xl">
                    Collections are for Teams
                  </h3>
                  <p className="text-sm text-gray-500 mt-4 text-center">
                    Collections are collaborative spaces for teams. Join an
                    existing team or create a new one to start organizing and
                    sharing insights.
                  </p>
                  <div className="flex justify-center mt-6 sm:mt-8">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-green-lighter1 text-base font-medium text-white hover:bg-brand-turq focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-lighter2 sm:ml-3 sm:w-auto sm:text-sm transition duration-150 ease-in-out"
                      onClick={() => {
                        setJoinTeamModalOpen(true);
                      }}
                    >
                      Create or Join a Team
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateCollectionModal;
