import React, { useEffect, useState, Fragment } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import classNames from "classnames";
import {
  MenuIcon,
  XIcon,
  HomeIcon,
  UsersIcon,
  FolderIcon,
  CogIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import ManageTeamModal from "./ManageTeamModal";
import { BarLoader } from "react-spinners";
import GoogleButton from "react-google-button";
import { GoogleDocsIcon } from "../svgs/svgs";
import amplitude from "amplitude-js";

import UnauthedNavbar from "./UnauthedNavbar";
import Avatar from "./Avatar";

const Navbar = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginLoaded, setLoginLoaded] = useState(false);
  const [isVisitor, setIsVisitor] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [user, setUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);

  const [loginStep, setLoginStep] = useState(1);
  const basicScopes =
    "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly";
  const driveScopes = "https://www.googleapis.com/auth/drive.file";

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const needsDriveAccess = query.get("needsDriveAccess");

  useEffect(() => {
    if (props.triggerTeamModal) {
      setIsTeamModalOpen(true);
      amplitude
        .getInstance()
        .logEvent("manage team modal opened from share meeting modal");
    }
  }, [props.triggerTeamModal]);

  useEffect(() => {
    props.setUser(user);
  }, [user]);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log("success", codeResponse);

      // After successful login, if we're in the first step, move to the next step
      if (loginStep === 1) {
        // Extract the authorization code from the response
        const authCode = codeResponse.code;

        // Make a request to your backend with the authorization code
        const resp = await fetch(
          `https://backend.scribbl.co/auth/code?code=${authCode}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // This line ensures cookies are sent with the request
            body: JSON.stringify({}),
          }
        );
        if (resp.status === 200) {
          const respJson = await resp.json();
          const email = respJson.email;
          const name = respJson.name;
          setFirstName(name);
          props.setUserEmail && props.setUserEmail(email);
          setEmail(email);

          let hasDriveAccess =
            codeResponse.scope && codeResponse.scope.includes("drive.file")
              ? true
              : false;
          console.log("has drive access?", hasDriveAccess);
          if (hasDriveAccess) {
            await getUserInfo();
            setLoggedIn(true);
            try {
              window.parent.postMessage("user signed in", "*");
            } catch (e) {
              console.log("error signing in", e);
            }
          } else {
            setLoginStep(2);
          }

          amplitude.getInstance().logEvent("webapp sign in with google 1");
        }
      } else if (loginStep === 2) {
        // Extract the authorization code from the response
        const authCode = codeResponse.code;

        // Make a request to your backend with the authorization code
        const resp = await fetch(
          `https://backend.scribbl.co/auth/code?code=${authCode}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // This line ensures cookies are sent with the request
            body: JSON.stringify({}),
          }
        );
        if (resp.status === 200) {
          await getUserInfo();
          setLoggedIn(true);
          try {
            window.parent.postMessage("user signed in", "*");
          } catch (e) {
            console.log("error signing in", e);
          }

          amplitude.getInstance().logEvent("webapp sign in with google 2");
        }
      }
    },
    onError: (err) => {
      console.log("error", err);
      if (props.isPublic) {
        setIsVisitor(true);
      }
    },
    onNonOAuthError: (err) => {
      console.log("non oauth err", err);
    },
    hint: email,
    flow: "auth-code",
    scope: loginStep === 1 ? basicScopes : driveScopes, // Change the scopes based on the login step
  });

  const getUserInfo = async () => {
    const userInfoResp = await fetch(
      `https://backend.scribbl.co/auth/userinfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    if (userInfoResp.status === 200) {
      const userInfoJson = await userInfoResp.json();
      console.log(userInfoJson);

      setUser(userInfoJson);

      if (needsDriveAccess) {
        const email = userInfoJson.email;
        const name = userInfoJson.name;
        setFirstName(name);
        props.setUserEmail && props.setUserEmail(email);
        setEmail(email);
        setLoggedIn(false);

        setLoginStep(2);
        return;
      }

      if (userInfoJson && userInfoJson.email) {
        props.setUser && props.setUser(userInfoJson);
        setLoggedIn(true);
        props.setFirstName && props.setFirstName(userInfoJson.givenName);
        props.setUserEmail && props.setUserEmail(userInfoJson.email);

        if (userInfoJson.onboardingStatus === 1) {
          setShowOnboarding(true);
        }

        const userProperties = {
          email: userInfoJson.email,
          account_type: userInfoJson.subscription
            ? userInfoJson.subscription
            : 0,
          org: userInfoJson.orgID,
        };
        amplitude.getInstance().setUserProperties(userProperties);
        amplitude.getInstance().setUserId(userInfoJson.email);
      }
    } else if (userInfoResp.status === 401) {
      setLoggedIn(false);
      if (props.isPublic) {
        setIsVisitor(true);
      }
      props.setLoading && props.setLoading(false);
    } else {
      if (props.isPublic) {
        setIsVisitor(true);
      }
    }
    setLoginLoaded(true);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (props.logout) {
      setLoggedIn(false);
    }
  }, [props.logout]);

  if (loggedIn && showOnboarding) {
    const currentPath = `${location.pathname}${location.search}`;
    const encodedPath = encodeURIComponent(currentPath);
    navigate(`/signup?returnPath=${encodedPath}`);
  }

  const navigation = [
    { name: "My Meetings", href: "/", icon: HomeIcon },
    { name: "Meetings Shared With Me", href: "/shared", icon: UsersIcon },
    { name: "Collections", href: "/collections", icon: FolderIcon },
    { name: "Settings", href: "/settings", icon: CogIcon },
  ];

  return (
    <div>
      {(loggedIn || (props.isPublic && loginLoaded)) && (
        <>
          {isVisitor && <UnauthedNavbar />}
          <div className="h-screen flex overflow-hidden">
            {/* Off-canvas menu for mobile */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 lg:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-40">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <XIcon
                            className="h-5 w-5 text-gray-900"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                        <div className="flex-shrink-0 flex items-center px-4">
                          <img
                            className="h-8 w-auto"
                            src="/icon_OnWhite.svg"
                            alt="Scribbl"
                          />
                        </div>
                        <nav className="mt-5 px-2 space-y-1">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.href === location.pathname
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.href === location.pathname
                                    ? "text-gray-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                  "mr-4 flex-shrink-0 h-5 w-5"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ))}
                        </nav>
                      </div>
                      <div className="flex-shrink-0 flex bg-gray-50 p-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user?.picture || "/default-avatar.png"}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-gray-800">
                            {user?.name}
                          </div>
                          <div className="text-sm font-medium text-gray-500">
                            {user?.email}
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                  <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-shrink-0">
              <div className="flex flex-col w-64">
                <div className="flex-1 flex flex-col min-h-0 bg-white border-r border-gray-200">
                  <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4">
                      <img
                        className="h-16 w-auto"
                        src="/Logo_OnWhite.svg"
                        alt="Scribbl"
                      />
                    </div>
                    <nav className="mt-5 flex-1 px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.href === location.pathname
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === location.pathname
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-3 flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex-shrink-0 bg-gray-50 p-4 space-y-3">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        {user && user.picture ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user?.picture || "/default-avatar.png"}
                            alt=""
                          />
                        ) : (
                          <Avatar email={user.email} name={user.name} />
                        )}
                      </div>
                      <div className="ml-3 min-w-0 flex-1">
                        <div className="text-sm font-medium text-gray-900 truncate">
                          {user?.name}
                        </div>
                        <div className="text-xs font-medium text-gray-500 truncate">
                          {user?.org?.name || "Your Team"}
                        </div>
                      </div>
                    </div>
                    <Link
                      onClick={() => {
                        setIsTeamModalOpen(true);
                        amplitude
                          .getInstance()
                          .logEvent("user opened manage team modal");
                      }}
                      className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-green hover:bg-brand-green-darker2"
                    >
                      Manage Team
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-auto focus:outline-none">
              <div className="lg:hidden">
                <div className="flex items-center justify-between px-4 py-1.5 border-b border-gray-200 bg-white">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="/icon_OnWhite.svg"
                      alt="Scribbl"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                      onClick={() => setSidebarOpen(true)}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <MenuIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <main className="h-screen">{props.children}</main>
            </div>
          </div>
        </>
      )}
      {loginStep === 2 && needsDriveAccess && (
        <div className="flex justify-center items-center flex-col pt-6">
          <GoogleDocsIcon className="mt-2" />
          <span className="text-center font-thin text-4xl text-gray-600">
            Give Scribbl access to Google Docs.
          </span>
          <span className="text-center font-thin text-lg text-gray-500">
            Continue to give Scribbl access to create Google Docs.
          </span>
          <div className="flex justify-center pt-5">
            <div>
              <button
                type="button"
                className="bg-brand-green inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-lighter2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-red"
                onClick={() => {
                  login();
                }}
              >
                Continue
                <ArrowRightIcon
                  className="-mr-0.5 h-7 w-7"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      )}
      {!loggedIn && !isVisitor && (
        <div
          className={
            !loginLoaded ? "hidden" : "grid grid-cols-2 w-screen h-screen"
          }
        >
          <div className="">
            <div className="grid grid-rows-3 pt-12 h-full">
              <div className="grid grid-cols-7">
                <div />
                <img
                  className="ml-4"
                  src="/Logo_OnWhite.png"
                  alt="Scribbl dragonfly logo"
                />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>

              <div className="flex">
                <div />
                {loginStep === 1 && (
                  <div className="grow flex justify-center flex-col pb-16">
                    <span className="text-center font-thin text-5xl text-gray-600">
                      Welcome to Scribbl{" "}
                    </span>
                    <span className="text-center font-thin text-lg text-gray-500">
                      Get started for free today.
                    </span>
                    <div className="flex justify-center pt-5">
                      <div>
                        <GoogleButton
                          type="light"
                          onClick={() => {
                            login();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {loginStep === 2 && (
                  <div className="grow flex justify-center items-center flex-col pb-16">
                    <span className="text-center font-thin text-5xl text-gray-600">
                      Welcome {firstName}
                    </span>
                    <GoogleDocsIcon className="mt-2" />
                    <span className="text-center font-thin text-4xl text-gray-600">
                      Give Scribbl access to Google Docs.
                    </span>
                    <span className="text-center font-thin text-lg text-gray-500">
                      Continue to give Scribbl access to create Google Docs.
                    </span>
                    <div className="flex justify-center pt-5">
                      <div>
                        <button
                          type="button"
                          className="bg-brand-green inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-lighter2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-red"
                          onClick={() => {
                            login();
                          }}
                        >
                          Continue
                          <ArrowRightIcon
                            className="-mr-0.5 h-7 w-7"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div />
              </div>
            </div>
          </div>
          <div className="bg-brand-red">
            <div className="p-20 pt-20 flex flex-col justify-center">
              <span className="text-center font-thin text-5xl text-white">
                Optimize your meetings.
              </span>
              <p className="px-6 pt-12 text-lg text-white text-center">
                Life is short and meetings are long. With Scribbl, you can
                quickly get up to speed on past meetings and feel confident that
                you'll never lose valuable conversations again.
              </p>
              <img
                className="xl:w-2/4 xl:h-2/4 w-4/5 h-4/5 self-center"
                src="/signin-image.png"
                alt="Sign in image"
              />
            </div>
          </div>
        </div>
      )}
      {props.loading && (
        <div
          className="flex flex-col justify-center items-center backdrop-blur-sm p-4 rounded-lg bg-black/10"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            className="h-16 w-auto mb-8"
            src="/Icon_OnWhite.svg"
            alt="Scribbl Logo"
          />
          <BarLoader
            className="mt-5"
            color="#E15C54"
            loading={props.loading}
            size={20}
          />
        </div>
      )}
      <ManageTeamModal
        isOpen={isTeamModalOpen}
        setIsOpen={setIsTeamModalOpen}
        user={user}
        setUser={setUser}
      />
    </div>
  );
};

export default Navbar;
