import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Menu, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import Attributes from "./recording/Attributes";
import MeetingData from "./recording/MeetingData";
import CustomOutput from "./recording/CustomOutput";
import OutOfCredits from "./recording/OutOfCredits";
import MeetingChat from "./recording/MeetingChat";
import AIChat from "./AIChat";
import { PencilAltIcon, ShareIcon, FolderIcon } from "@heroicons/react/outline";
import { DotsVerticalIcon } from "@heroicons/react/solid";

import { debounce } from "lodash";

import { sendSlackMessage } from "../slack";
import amplitude from "amplitude-js";

import "react-multi-email/style.css";
import AddMeetingToCollectionModal from "./collections/AddMeetingToCollectionModal";
import ShareMeetingModal from "./ShareMeetingModal";

var moment = require("moment");

const Meeting = (props) => {
  let transcriptParentRef = useRef();
  const overviewRef = useRef();

  const txItemRefs = useRef([]);

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const { uuid } = useParams();

  const [meeting, setMeeting] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [editingName, setEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState(null);

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [shareWithTeam, setShareWithTeam] = useState(false);

  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false);

  // This is for the EmailEntry component when typing
  // We need access to this for when the modal is closed but there is a valid email in the input
  const copyLinkInitialState = "Copy link";
  const [copyLinkText, setCopyLinkText] = useState(copyLinkInitialState);

  const [tabs, setTabs] = useState([
    { name: "Overview", href: "#", current: true },
    { name: "Meeting Chat", href: "#", current: false },
    { name: "AI Copilot", href: "#", current: false },
  ]);

  const [transcriptParentHeight, setTranscriptParentHeight] = useState(0);

  const [searchResults, setSearchResults] = useState([]);
  const [meetingData, setMeetingData] = useState({});
  const [isInitialMeetingState, setIsInitialMeetingState] = useState(true);

  const [availableHeight, setAvailableHeight] = useState(0);
  const tabsRef = useRef(null);

  const calculateAvailableHeight = () => {
    const headerHeight = tabsRef.current?.offsetHeight || 0;
    const availableHeight = window.innerHeight - headerHeight;

    setAvailableHeight(availableHeight);
  };

  useEffect(() => {
    console.log("the meeting is", meeting);
    if (meeting && isInitialMeetingState) {
      const meetingData = meeting.gptMeetingData;
      try {
        const jsonMD = JSON.parse(meetingData);
        setMeetingData(jsonMD);
      } catch (error) {
        console.log("couldn't parse meeting data", meetingData);
      }
      // Sort tx items
      meeting.transcriptItems.sort((a, b) =>
        a.itemStart > b.itemStart ? 1 : -1
      );

      if (meeting) {
        txItemRefs.current = meeting.transcriptItems.map(
          (_, i) => txItemRefs.current[i] ?? React.createRef()
        );
      }
      setIsInitialMeetingState(false);
    }

    calculateAvailableHeight();
    window.addEventListener("resize", calculateAvailableHeight);
    return () => {
      window.removeEventListener("resize", calculateAvailableHeight);
    };
  }, [meeting]);

  useEffect(() => {
    //Change copy link text to show that a link was successfully copied.
    if (copyLinkText !== copyLinkInitialState) {
      setTimeout(() => setCopyLinkText(copyLinkInitialState), [1000]);
    }
  }, [copyLinkText]);

  useEffect(() => {
    if (props.userEmail) {
      setUserEmail(props.userEmail);
    }
    if (props.user) {
      setUser(props.user);
    }
  }, [props.userEmail, props.user]);

  useEffect(() => {
    if (props.meeting) {
      setMeeting(props.meeting);
      // Maintain a different object for share settings so that you don't have to update the recording state
      // sharedWithEmails and orgID should go in this new share settings state so we can figure out who it's shared with and if it's shared with the team
      setShareEmails(props.meeting.sharedWithEmails);
      setShareWithTeam(props.meeting.orgID > 0 || false);
    }
  }, [props.meeting]);

  // When the search term changes
  const debounceTranscriptSearchChanged = useCallback(
    debounce(() => {
      transcriptSearchChanged(searchTerm, setSearchResults, meeting);
    }, 300)
  );

  useEffect(() => {
    debounceTranscriptSearchChanged();
  }, [searchTerm]);

  useEffect(() => {
    calculateElementHeight();
    window.addEventListener("resize", calculateElementHeight);
    return () => {
      window.removeEventListener("resize", calculateElementHeight);
    };
  }, [transcriptParentRef.current]);

  useEffect(() => {
    calculateElementHeight();
  }, [window.innerHeight]);

  function calculateElementHeight() {
    if (transcriptParentRef.current) {
      /* calculates the height of the transcript window */
      let height =
        window.innerHeight -
        (transcriptParentRef.current.getBoundingClientRect().top +
          window.scrollY);
      setTranscriptParentHeight(height);
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function removeShareEmail(email) {
    let reqSharedEmail = shareEmails.filter((i) => i !== email);
    setShareEmails(reqSharedEmail);
    shareRecording(
      uuid,
      userEmail,
      shareWithTeam,
      reqSharedEmail,
      setShareWithTeam,
      setShareEmails
    );
  }

  let currentSearchIndex = 0;
  searchResults.forEach((result, index) => {
    if (result.currentItem) {
      currentSearchIndex = index;
    }
  });

  const nextSearchResult = (currentIndex) => {
    if (currentIndex + 1 < searchResults.length) {
      searchResults[currentIndex + 1].currentItem = true;
      searchResults[currentIndex].currentItem = false;
      const nextID = searchResults[currentIndex + 1].ID;
      meeting.transcriptItems.forEach((txItem, index) => {
        txItem.currentItem = false;

        if (nextID === txItem.ID) {
          txItem.currentItem = true;
          const updatedTxItem = structuredClone(txItem);
          meeting.transcriptItems[index] = updatedTxItem;
          setTimeout(() => {
            txItemRefs.current[index].current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      });

      let newMeetingData = structuredClone(meeting);
      setMeeting(newMeetingData);
    }
  };

  const prevSearchResult = (currentIndex) => {
    if (currentIndex - 1 >= 0) {
      searchResults[currentIndex - 1].currentItem = true;
      searchResults[currentIndex].currentItem = false;
      const nextID = searchResults[currentIndex - 1].ID;

      meeting.transcriptItems.forEach((txItem, index) => {
        txItem.currentItem = false;

        if (nextID === txItem.ID) {
          txItem.currentItem = true;
          const updatedTxItem = structuredClone(txItem);
          meeting.transcriptItems[index] = updatedTxItem;
          setTimeout(() => {
            txItemRefs.current[index].current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      });

      let newMeetingData = structuredClone(meeting);
      setMeeting(newMeetingData);
    }
  };

  function transcriptSearchChanged(searchTerm, setSearchResults, meeting) {
    if (meeting) {
      let newMeetingData = { ...meeting }; // copy the meeting data

      // initialize the modified transcriptItems array
      let modifiedTranscriptItems = meeting.transcriptItems.map(
        (txItem, index) => {
          let matchesSearchTerm =
            searchTerm &&
            txItem.transcript.toLowerCase().includes(searchTerm.toLowerCase());

          return {
            ...txItem,
            matchesSearchTerm,
          };
        }
      );

      // replace the old transcriptItems array
      newMeetingData.transcriptItems = modifiedTranscriptItems;
      setMeeting(newMeetingData);

      // handle search results
      let results = modifiedTranscriptItems.filter(
        (txItem) => txItem.matchesSearchTerm
      );
      setSearchResults(results);

      // scroll to first search result
      if (results.length > 0) {
        let scrollToIndex = modifiedTranscriptItems.findIndex(
          (txItem) => txItem.matchesSearchTerm
        );
        setTimeout(() => {
          txItemRefs.current[scrollToIndex].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
  }

  function calculateMeetingDuration() {
    if (meeting.transcriptItems && meeting.transcriptItems.length) {
      return meeting.transcriptItems[meeting.transcriptItems.length - 1]
        .itemEnd;
    }
  }

  function getMeetingAttendees() {
    if (meeting.transcriptItems) {
      let atendees = [];
      meeting.transcriptItems.forEach((txItem) => {
        if (!atendees.includes(txItem.speaker)) {
          atendees.push(txItem.speaker);
        }
      });
      return atendees;
    }
  }

  function scrollToTopicArea(topic) {
    console.log("topic clicked", topic);
    let cont = true;
    meeting.transcriptItems.forEach((txItem, index) => {
      if (cont && txItem.itemStart >= topic.start) {
        cont = false;
        setTimeout(() => {
          txItemRefs.current[index].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    });
  }

  function scrollToActionItemArea(actionItem) {
    console.log("action item clicked", actionItem);
    let cont = true;
    meeting.transcriptItems.forEach((txItem, index) => {
      if (cont && txItem.itemStart >= actionItem.time) {
        cont = false;
        setTimeout(() => {
          txItemRefs.current[index].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    });
  }

  return (
    <div className="h-full w-full">
      <>
        {meeting && (
          <div className="h-full max-h-screen flex flex-col w-full">
            <div className="flex flex-col min-w-0 flex-1">
              <div className="flex-1 relative z-0 flex">
                <main className="flex-1 relative z-0 focus:outline-none">
                  <div
                    className="bg-white border-b border-gray-200 sm:px-6 w-full px-5 grid grid-cols-2"
                    style={{ height: "55px" }}
                  >
                    {editingName ? (
                      <div className="flex items-center pb-3 w-full pt-2 grid-span-1">
                        <input
                          className="shadow-sm focus:ring-stone-400 focus:border-stone-400 block w-1/3 sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => setEditingNameValue(e.target.value)}
                          type="text"
                          value={editingNameValue}
                        />
                        <button
                          type="button"
                          className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-brand-green bg-stone-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-200"
                          onClick={() => setEditingName(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-red hover:bg-brand-red-darker1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                          onClick={async () => {
                            const renameMeetingResp = await fetch(
                              `https://backend.scribbl.co/enhanced-recording/${uuid}/rename`,
                              {
                                method: "POST",
                                body: JSON.stringify({
                                  email: userEmail,
                                  name: editingNameValue,
                                }),
                                credentials: "include", // This line ensures cookies are sent with the request
                              }
                            );
                            const respJson = await renameMeetingResp.json();
                            meeting.meetingTitle = respJson.name;
                            setMeeting(meeting);
                            setEditingName(false);
                            if (userEmail && userEmail !== "mike@scribbl.co") {
                              sendSlackMessage(
                                "beta",
                                `${userEmail} renamed recording ${uuid} to ${respJson.name}`
                              );
                            }
                          }}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <span className="flex justify-self-start text-l text-brand-gray my-auto">
                        {meeting.meetingTitle}
                        {!props?.isPublic && (
                          <div className="flex align-items-top pl-3 pt-1">
                            <PencilAltIcon
                              className="h-4 w-4 cursor-pointer invisible sm:visible"
                              aria-hidden="true"
                              onClick={() => {
                                setEditingNameValue(meeting.meetingTitle);
                                setEditingName(true);
                              }}
                            />
                          </div>
                        )}
                      </span>
                    )}
                    <div className="flex justify-end">
                      {meeting?.googleDocLink &&
                        !props?.isPublic &&
                        // We need to check if the user is part of the org that owns the recording here
                        meeting.role === 1 && (
                          <button
                            onClick={() =>
                              window
                                .open(meeting.googleDocLink, "_blank")
                                .focus()
                            }
                            className=" hidden 2xl:inline-flex flex-none h-10 my-auto mr-2 justify-self-end items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            <img
                              className="h-5 mr-3"
                              src="/google-docs-icon.png"
                              alt="Scribbl dragonfly logo"
                            />
                            Google Doc
                          </button>
                        )}

                      <AddMeetingToCollectionModal
                        isOpen={addCollectionModalOpen}
                        onClose={() => setAddCollectionModalOpen(false)}
                        meetingId={meeting.ID}
                      />

                      {!props?.isPublic && user?.orgID && (
                        <button
                          className="mr-3 hidden 2xl:inline-flex flex-none h-10 my-auto justify-self-end items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          onClick={() => {
                            setAddCollectionModalOpen(true);
                          }}
                        >
                          <FolderIcon
                            className="stroke-gray-700 mr-3 -ml-1 h-5 w-5"
                            aria-hidden="true"
                          />
                          Add to a Collection
                        </button>
                      )}

                      {!props?.isPublic &&
                        // We need to check if the user is part of the org that owns the recording here
                        (meeting.role === 1 || meeting.role === 2) && (
                          <button
                            className="hidden 2xl:inline-flex flex-none h-10 my-auto justify-self-end items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => {
                              setShareModalOpen(true);
                            }}
                          >
                            <ShareIcon
                              className="stroke-gray-700 mr-3 -ml-1 h-4 w-4"
                              aria-hidden="true"
                            />
                            Share Meeting
                          </button>
                        )}

                      <Menu
                        as="div"
                        className="relative inline-flex 2xl:hidden text-left my-auto"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full p-2 bg-gray-100 text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <DotsVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {meeting?.googleDocLink &&
                                !props?.isPublic &&
                                // We need to check if the user is part of the org that owns the recording here
                                meeting.role === 1 && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          window
                                            .open(
                                              meeting.googleDocLink,
                                              "_blank"
                                            )
                                            .focus()
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block w-full px-4 py-2 text-left text-sm"
                                        )}
                                      >
                                        <img
                                          className="h-5 mr-3 inline"
                                          src="/google-docs-icon.png"
                                          alt="Scribbl dragonfly logo"
                                        />
                                        Google Doc
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              {!props?.isPublic &&
                                // We need to check if the user is part of the org that owns the recording here
                                (meeting.role === 1 || meeting.role === 2) && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          setShareModalOpen(true);
                                        }}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block w-full px-4 py-2 text-left text-sm"
                                        )}
                                      >
                                        <ShareIcon
                                          className="stroke-gray-700 mr-3 h-4 w-4 inline"
                                          aria-hidden="true"
                                        />
                                        Share Meeting
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              {!props?.isPublic && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => {
                                        setAddCollectionModalOpen(true);
                                      }}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block w-full px-4 py-2 text-left text-sm"
                                      )}
                                    >
                                      <FolderIcon
                                        className="stroke-gray-700 mr-3 h-4 w-4 inline"
                                        aria-hidden="true"
                                      />
                                      Add to Collection
                                    </button>
                                  )}
                                </Menu.Item>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="">
                    <div className="  inset-0 pt-6 px-4 sm:px-6 lg:px-8">
                      <div className="  rounded-lg">
                        {/*Transcript Search */}
                        <div className="mb-4">
                          <div className="relative w-full">
                            <div>
                              {/* Tx search */}
                              <div
                                style={{
                                  border: "1px solid lightgray",
                                  borderRadius: "3px",
                                  boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
                                }}
                                className="flex items-center w-full h-[40px] bg-white"
                              >
                                <input
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter" && e.shiftKey) {
                                      prevSearchResult(currentSearchIndex);
                                    } else if (e.key === "Enter") {
                                      nextSearchResult(currentSearchIndex);
                                    }
                                  }}
                                  className="borderless w-full"
                                  autoComplete="off"
                                  type="text"
                                  name="search-transcript"
                                  id="search-tx"
                                  placeholder="Search Transcript"
                                  value={searchTerm}
                                  onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                  }}
                                />
                                <span
                                  className="pr-[14px] text-[gray] w-[39px]"
                                  style={{ fontSize: "14px" }}
                                >
                                  {searchResults.length
                                    ? currentSearchIndex + 1
                                    : 0}
                                  /{searchResults.length}
                                </span>
                                <div
                                  className="h-[70%]"
                                  style={{
                                    borderLeft: "1px solid lightgray",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                ></div>
                                {/* Up arrow */}
                                <button
                                  onClick={() => {
                                    prevSearchResult(currentSearchIndex);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="gray"
                                    className="w-4 h-4 ml-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                    />
                                  </svg>
                                </button>
                                {/* Down arrow */}
                                <button
                                  onClick={() => {
                                    nextSearchResult(currentSearchIndex);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="gray"
                                    className="w-4 h-4 ml-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </button>

                                {/* Clear search */}
                                <button onClick={() => setSearchTerm("")}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="gray"
                                    className="w-4 h-4 mx-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Transcript Start */}
                        <div
                          className="overflow-y-auto"
                          ref={transcriptParentRef}
                          style={{ maxHeight: `${transcriptParentHeight}px` }}
                        >
                          {meeting?.transcriptItems?.map((txitem, index) => {
                            return (
                              <div
                                key={index}
                                ref={txItemRefs.current[index]}
                                className={classNames(
                                  txitem.matchesSearchTerm
                                    ? "bg-yellow-200 hover:bg-yellow-300"
                                    : "bg-white hover:bg-gray-50",
                                  "txitem-parent-block grid grid-cols-9 mb-2 px-1 rounded text-gray-800"
                                )}
                              >
                                <div className="txitem-metadata-block col-span-2 select-none border-r py-1">
                                  <div className="font-semibold">
                                    {txitem.speaker}
                                  </div>
                                  <div className="text-sm">
                                    {formatTranscriptItemTime(txitem.itemStart)}
                                  </div>
                                </div>
                                <div className="col-span-7 ml-5 py-1">
                                  {txitem.transcript}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                {/* End main area */}

                {/* Start secondary column (hidden on smaller screens) */}
                <aside className="z-[-1] overflow-y-auto hidden bg-gray-50 relative md:flex md:flex-col flex-shrink-0 w-5/12 border-l border-gray-200">
                  <div className="absolute inset-0 px-1">
                    <div className="rounded-lg">
                      {/* Tabs */}
                      <div ref={tabsRef} className="px-3">
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {tabs.map(
                                (tab) =>
                                  ((props?.isPublic && tab.name !== "Clips") ||
                                    !props?.isPublic) && (
                                    <a
                                      key={tab.name}
                                      className={classNames(
                                        tab.current
                                          ? "border-brand-green-lighter3 text-brand-green"
                                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-brand-green-lighter3",
                                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                                      )}
                                      aria-current={
                                        tab.current ? "page" : undefined
                                      }
                                      onClick={() => {
                                        setCurrentTab(tabs, setTabs, tab.name);
                                        var eventProperties = {
                                          recording_uuid: uuid,
                                          to_tab: tab.name,
                                        };
                                        amplitude
                                          .getInstance()
                                          .logEvent(
                                            "er toggle recording tab",
                                            eventProperties
                                          );
                                      }}
                                    >
                                      {tab.name}
                                    </a>
                                  )
                              )}
                            </nav>
                          </div>
                        </div>
                      </div>
                      {/* Overview Section */}
                      {tabs[0].current && (
                        <div ref={overviewRef}>
                          {/* Attributes Card */}
                          <Attributes
                            meetingCreatedAt={meeting.CreatedAt}
                            meetingDuration={calculateMeetingDuration() * 1000}
                            attendees={getMeetingAttendees()}
                          />

                          <CustomOutput meeting={meeting} />

                          {/* Meeting Data Cards */}
                          <MeetingData
                            user={user}
                            topics={meetingData.topics}
                            actionItems={meetingData.action_items}
                            meetingTitle={meeting.meetingTitle}
                            topicClicked={scrollToTopicArea}
                            actionItemClicked={scrollToActionItemArea}
                            userEmail={userEmail}
                            meetingUUID={meeting.uuid}
                            meetingData={meetingData}
                            setMeetingData={setMeetingData}
                          />
                        </div>
                      )}
                      {/* Meeting Chat Section */}
                      {tabs[1].current && (
                        <MeetingChat chatMessages={meeting.chatMessages} />
                      )}
                      {tabs[2].current && (
                        <AIChat
                          uuid={uuid}
                          userEmail={userEmail}
                          initialAIChat={meeting.aiChat}
                          maxHeight={availableHeight + "px"}
                          isProUser={
                            user.subscription &&
                            user.subscription.accountType !== 0
                          }
                        />
                      )}
                    </div>
                  </div>
                </aside>
                {meeting?.isLocked && (
                  <OutOfCredits user={user} userEmail={userEmail} />
                )}
              </div>
            </div>
          </div>
        )}
        {/* Share recording modal */}
        {meeting && user && (
          <ShareMeetingModal
            isOpen={shareModalOpen}
            onClose={() => {
              setShareModalOpen(false);
            }}
            user={user}
            userEmail={user.email}
            uuid={meeting.uuid}
            key={meeting.uuid}
            setTriggerTeamModal={props.setTriggerTeamModal}
          />
        )}
      </>
    </div>
  );
};

export default Meeting;

const setCurrentTab = (tabs, setTabs, tabName) => {
  let newTabs = [...tabs];
  newTabs.forEach((t) => {
    if (tabName === t.name) {
      t.current = true;
    } else {
      t.current = false;
    }
  });
  setTabs(newTabs);
};

const getShareLink = async (uuid, userEmail) => {
  const shareRecordingResponse = await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share`,
    {
      method: "POST",
      body: JSON.stringify({
        email: userEmail,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    }
  );
  const respJson = await shareRecordingResponse.json();
  copySharedMeetingToClipboard(respJson.uuid);
};

async function copySharedMeetingToClipboard(meetingUUID) {
  if (!navigator.clipboard) {
    console.log("clipboard copy not supported by browser");
    // Clipboard API not available
    return;
  }
  try {
    await navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/share/meeting/${meetingUUID}`
    );
  } catch (err) {
    console.log("failed to copy link:" + err);
  }
}

const shareRecording = async (
  uuid,
  userEmail,
  shareWithTeam,
  shareEmails,
  setShareWithTeam,
  setShareEmails
) => {
  // put fetch here
  const shareResp = await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share/update?email=${userEmail}&uuid=${uuid}`,
    {
      method: "POST",
      body: JSON.stringify({
        sharedWithEmails: shareEmails,
        sharedWithTeam: shareWithTeam,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    }
  );
  const shareResponse = await shareResp.json();
  setShareWithTeam(shareResponse.sharedWithTeam);
  setShareEmails(shareResponse.sharedWithEmails);
  var eventProperties = {
    recording_uuid: uuid,
    users_shared_with: shareEmails,
    shared_with_team: shareWithTeam,
    number_users_shared_with: shareEmails.length,
  };
  amplitude.getInstance().logEvent("er recording shared", eventProperties);
};

function formatTranscriptItemTime(itemStart) {
  //const itemTime =
  //  itemStart >= 0 ? itemStart - recordingTimeOffset : 0;
  let startTime = moment("2015-01-01").startOf("day").seconds(itemStart);

  if (startTime.hour() !== 0) {
    return startTime.format("H:mm:ss");
  } else {
    return startTime.format("mm:ss");
  }
}
