import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InviteModal from "../InviteModal";
import TeamPricing from "./TeamPricing";

import { ExclamationIcon, XIcon, UserAddIcon } from "@heroicons/react/outline";

import amplitude from "amplitude-js";

import moment from "moment";

const TeamSettings = (props) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [ownedSubscriptions, setOwnedSubscriptions] = useState([]);
  const [ownedSubscriptionsMap, setOwnedSubscriptionsMap] = useState([]);
  const [openPricingModal, setOpenPricingModal] = useState(false);
  const [unassignedSubscriptions, setUnassignedSubscriptions] = useState([]);
  const [openConfirmUnassignModal, setOpenConfirmUnassignModal] =
    useState(false);
  const [openUnsubscrbenModal, setOpenUnsubscrbenModal] = useState(false);
  const [subscriptionToRemove, setSubscriptionToRemove] = useState(null);
  const [subscriptionToUnsubscribe, setSubscriptionToUnsubscribe] =
    useState(null);

  useEffect(() => {
    getTeamMembers();
    getOwnedSubscriptions();
  }, []);

  useEffect(() => {
    if (teamMembers && ownedSubscriptions) {
      let tempOwnedSubscriptionsMap = {};
      ownedSubscriptions.forEach((subscription) => {
        tempOwnedSubscriptionsMap[subscription.userID] = subscription;
      });
      console.log(tempOwnedSubscriptionsMap);
      setOwnedSubscriptionsMap(tempOwnedSubscriptionsMap);
    }
  }, [teamMembers, ownedSubscriptions]);

  useEffect(() => {
    let tempUnassignedSubscriptions = 0;
    ownedSubscriptions.forEach((subscription) => {
      if (!subscription.userID) {
        tempUnassignedSubscriptions++;
      }
    });

    setUnassignedSubscriptions(tempUnassignedSubscriptions);
  }, [ownedSubscriptions]);

  useEffect(() => {
    console.log("ownedSubscriptionsMap", ownedSubscriptionsMap);
  }, [ownedSubscriptionsMap]);

  async function getTeamMembers() {
    if (props.userEmail) {
      let url = "https://backend.scribbl.co/orgs/members?include_pending=true";
      const getTeamMembersResponse = await fetch(url, {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      });

      const orgTeamMembers = await getTeamMembersResponse.json();
      if (orgTeamMembers) {
        setTeamMembers(
          orgTeamMembers
            .sort(
              (a, b) =>
                moment(a.CreatedAt, "YYYY-MM-DDTHH:mm:ss") -
                moment(b.CreatedAt, "YYYY-MM-DDTHH:mm:ss")
            )
            .sort((a, b) => {
              // Bring the current user to the top of the list
              if (a.email === props.userEmail) return -1;
              if (b.email === props.userEmail) return 1;
              return 0;
            })
        );
      }
    }
  }

  async function getOwnedSubscriptions() {
    if (props.userEmail) {
      let url = "https://backend.scribbl.co/user/subscriptions";
      const getOwnedSubscriptionsResponse = await fetch(url, {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      });

      const ownedSubscriptionsResp = await getOwnedSubscriptionsResponse.json();
      if (ownedSubscriptionsResp) {
        setOwnedSubscriptions(ownedSubscriptionsResp);
      }
    }
  }

  function getSubscriptionType(user) {
    if (user.orgID === null) {
      return "Pending";
    }
    if (user.subscription) {
      return "Pro";
    }
    return "Lite";
  }

  function showAssignSubscriptionButton(user) {
    /*show this button if a user owns subscriptions, has subscriptions avalilable and the member does not have a subscription*/
    if (
      ownedSubscriptions &&
      unassignedSubscriptions != 0 &&
      !user.subscription
    ) {
      return true;
    }

    return false;
  }

  function showRemoveSubscriptionButton(user) {
    /*show this button if a user owns subscriptions, and the member already has a subscription*/
    if (
      ownedSubscriptions &&
      user.subscription &&
      user.ID in ownedSubscriptionsMap
    ) {
      return true;
    }

    return false;
  }

  async function assignSubscription(member) {
    const assignSubscriptionResp = await fetch(
      `https://backend.scribbl.co/user/assign-subscription`,
      {
        method: "POST",
        body: JSON.stringify({
          email: member.email,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    const subscription = await assignSubscriptionResp.json();
    if (subscription && subscription.ID) {
      let tmpTeamMembers = teamMembers.map((teamMember) => {
        if (teamMember.ID === subscription.userID) {
          teamMember.subscription = subscription;
        }
        return teamMember;
      });
      setTeamMembers(tmpTeamMembers);
      let tmpOwnedSubscriptions = ownedSubscriptions.map(
        (ownedSubscription) => {
          if (ownedSubscription.ID === subscription.ID) {
            return subscription;
          } else {
            return ownedSubscription;
          }
        }
      );
      setOwnedSubscriptions(tmpOwnedSubscriptions);
    } else {
      console.log("Error assigning subscription");
    }
  }

  async function unassignSubscription(member) {
    const unassignSubscriptionResp = await fetch(
      `https://backend.scribbl.co/user/unassign-subscription`,
      {
        method: "POST",
        body: JSON.stringify({
          subscriptionID: member.subscription.ID,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    const subscription = await unassignSubscriptionResp.json();
    if (subscription && subscription.ID) {
      let tmpTeamMembers = teamMembers.map((teamMember) => {
        if (teamMember.ID === member.ID) {
          teamMember.subscription = null;
        }
        return teamMember;
      });
      setTeamMembers(tmpTeamMembers);
      let tmpOwnedSubscriptions = ownedSubscriptions.map(
        (ownedSubscription) => {
          if (ownedSubscription.ID === subscription.ID) {
            return subscription;
          } else {
            return ownedSubscription;
          }
        }
      );
      setOwnedSubscriptions(tmpOwnedSubscriptions);
    } else {
      console.log("Error assigning subscription");
    }
  }

  async function cancelSubscription(user) {
    if (user?.subscription?.ID) {
      const unassignSubscriptionResp = await fetch(
        `https://backend.scribbl.co/user/cancel`,
        {
          method: "POST",
          body: JSON.stringify({
            subscriptionID: user.subscription.ID,
          }),
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );
      let tmpTeamMembers = teamMembers.map((member) => {
        if (member.ID === user.ID) {
          let tmpMember = structuredClone(member);
          tmpMember.subscription.accountType = 0;
          return tmpMember;
        } else {
          return member;
        }
      });
      console.log("tmp Team Members", tmpTeamMembers);
      setTeamMembers(tmpTeamMembers);
    }
  }

  return (
    <div className="w-full flex flex-col py-12 px-10 bg-gray-50 overflow-y-auto">
      <div className="bg-white border border-gray-200 rounded-md p-5">
        <div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {props.user.org.name}
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  View users and invite users to your team to more seamlessly
                  share Scribbl meetings and insights
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none align-top">
                <button
                  type="button"
                  onClick={() => setOpenPricingModal(true)}
                  className="relative inline-flex align-top items-center rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2.5"
                >
                  Upgrade Team
                </button>
                <button
                  onClick={() => setOpenInviteModal(true)}
                  className="relative inline-flex items-center rounded-md bg-brand-green px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2"
                >
                  <UserAddIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                  Invite User
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-6">
              <div className="bg-gray-100 h-16 rounded-md text-gray-700 text-center flex justify-center items-center">
                <span className="text-2xl">{ownedSubscriptions.length}</span>
                <span className="ml-2 text-sm">Subscriptions Owned by Me</span>
              </div>
              <div className="bg-gray-100 h-16 rounded-md text-gray-700 text-center flex justify-center items-center">
                <span className="text-2xl">{unassignedSubscriptions}</span>
                <span className="ml-2 text-sm">Unassigned Subscriptions</span>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          User Since
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Account Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {teamMembers.map((member) => (
                        <tr key={member.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {member.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {member.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment(new Date(member.CreatedAt)).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {getSubscriptionType(member) === "Lite" && (
                              <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                                Lite
                              </span>
                            )}
                            {getSubscriptionType(member) === "Pro" && (
                              <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                                Pro
                              </span>
                            )}
                            {getSubscriptionType(member) === "Pending" && (
                              <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                                Team Invite Pending
                              </span>
                            )}
                          </td>
                          {showAssignSubscriptionButton(member) && (
                            <td>
                              <button
                                onClick={() => {
                                  assignSubscription(member);
                                }}
                                type="button"
                                className="ml-5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Assign Subscription
                              </button>
                            </td>
                          )}
                          {showRemoveSubscriptionButton(member) && (
                            <td>
                              <div class="flex items-center">
                                <button
                                  onClick={() => {
                                    setSubscriptionToRemove(member);
                                    setOpenConfirmUnassignModal(true);
                                  }}
                                  type="button"
                                  className="ml-5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-yellow-50"
                                >
                                  Unassign Subscription
                                </button>
                                {member?.subscription?.accountType != 0 ? (
                                  <button
                                    onClick={() => {
                                      setSubscriptionToUnsubscribe(member);
                                      setOpenUnsubscrbenModal(true);
                                    }}
                                    type="button"
                                    className="ml-5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-red-100 hover:bg-red-50"
                                  >
                                    Cancel Subscription
                                  </button>
                                ) : (
                                  <div className="ml-6 text-xs inline-flex ml-2">
                                    <div class="flex flex-col text-center text-gray-700">
                                      <div>Cancelled: </div>
                                      <div>
                                        Expires on{" "}
                                        {moment(
                                          new Date(member.subscription.subDate)
                                        ).format("MM/DD/YYYY")}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Transition.Root show={openConfirmUnassignModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpenConfirmUnassignModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                      onClick={() => setOpenConfirmUnassignModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-yellow-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Unassign Subscription
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-base text-gray-800">
                          Are you sure you want to unassign the subscription for{" "}
                          <span className="font-semibold">
                            {subscriptionToRemove?.name}
                          </span>
                          ?
                        </p>
                        <p className="text-sm text-gray-600 mt-4">
                          Once unassigned, you can reassign the subscription to
                          anyone on your team.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-800 text-base font-medium text-white hover:bg-yellow-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        unassignSubscription(subscriptionToRemove);
                        setOpenConfirmUnassignModal(false);
                        setSubscriptionToRemove(null);
                      }}
                    >
                      Unassign Subscription
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpenConfirmUnassignModal(false);
                        setSubscriptionToRemove(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={openUnsubscrbenModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpenUnsubscrbenModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                      onClick={() => setOpenUnsubscrbenModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Remove Subscription
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-base text-gray-800">
                          Are you sure you want to cancel the subscription for{" "}
                          <span className="font-semibold">
                            {subscriptionToUnsubscribe?.name}
                          </span>
                          ?
                        </p>
                        <p className="text-sm text-gray-600 mt-4">
                          They'll still have access to Scribbl until the end of
                          the subscription period, but they won't be included in
                          the next invoice.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        cancelSubscription(subscriptionToUnsubscribe);
                        setOpenUnsubscrbenModal(false);
                        setSubscriptionToUnsubscribe(null);
                      }}
                    >
                      Cancel Subscription
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpenUnsubscrbenModal(false);
                        setSubscriptionToUnsubscribe(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      {props.user && props.user.org && (
        <div>
          <InviteModal
            open={openInviteModal}
            setOpen={setOpenInviteModal}
            org={props.user.org}
            email={props.userEmail}
          />
          <TeamPricing
            open={openPricingModal}
            setOpen={setOpenPricingModal}
            userEmail={props.userEmail}
          />
        </div>
      )}
    </div>
  );
};

export default TeamSettings;
