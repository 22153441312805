import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CalendarIcon,
  UserGroupIcon,
  TrashIcon,
  LockClosedIcon,
} from "@heroicons/react/outline";

import "../index.css";
var moment = require("moment");

const ListViewRecording = (props) => {
  const recording = props.recording;
  /*const duration = calculateDuration(recording);*/
  const searchTerm = props.searchTerm;

  //Routing
  const navigate = useNavigate();

  const shouldShowTrashIcon = () => {
    // show the trash icon when a user is viewing my meetings or shared with me, don't show it for team meetings, and only when they're an owner in all meetings
    // there's probably a better way to do this without using listviewContext, but this was the fastest approach to get most of the way there.
    // at the time of implementing this, the thing that throws a wrench in this is that for team meetings, users can end up with role =3 which isn't easily differentiated from other viewer roles. This really only impacts all_meetings.
    var shouldDisplay = false;
    if (props.listviewContext === "my_meetings") {
      shouldDisplay = true;
    } else if (
      props.listviewContext === "all_meetings" &&
      (recording.role === 1 || recording.role === 2)
    ) {
      shouldDisplay = true;
    }

    return shouldDisplay;
  };

  return (
    <a
      key={recording.id}
      href={`/meeting/${recording.uuid}`}
      className="list-view-meeting-link py-5 flex cursor-pointer px-4 sm:px-6 hover:bg-gray-50"
      style={{ zIndex: "-1" }}
      onClick={(e) => {
        // Check if neither ctrlKey nor metaKey (Cmd key on Mac) were pressed
        if (!e.ctrlKey && !e.metaKey) {
          // Prevent the default link behavior
          e.preventDefault();

          // Use React Router's navigate method to change routes
          navigate(`/meeting/${recording.uuid}`);
        }
      }}
    >
      <div className="relative">
        {recording.containsVideo ? (
          <img
            style={{
              maxHeight: "80px",
            }}
            className="rounded"
            src={
              recording.coverThumbnail
                ? recording.coverThumbnail
                : "/no-thumbnail.png"
            }
          />
        ) : (
          <div
            className="bg-gray-100 align-middle	border rounded border-gray-200"
            style={{
              height: "80px",
              width: "142px",
            }}
          >
            <img
              className="mx-auto"
              src={"/transcript-only-icon.svg"}
              style={{
                maxHeight: "60px",
                marginTop: "10px",
              }}
            ></img>
          </div>
        )}
        {recording && recording.isLocked && (
          <div className="bg-gray-900/70 absolute top-0 left-0 h-full w-full rounded">
            <LockClosedIcon
              className="h-8 w-8 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              // aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="ml-5 flex flex-col">
        <span className="text-base font-normal text-gray-900 -mt-2">
          {highlightSearchTerm(recording.meetingTitle, searchTerm)}
          {recording.containsVideo && recording.isProcessing && (
            <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1 w-4 h-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              Processing
            </span>
          )}
        </span>
        <div className="flex items-center">
          <CalendarIcon
            className="h-4 w-4 text-brand-green-lighter3"
            aria-hidden="true"
          />
          <span className="pl-1 text-sm text-gray-500">
            {moment.utc(recording.CreatedAt, "YYYY-MM-DDTHH:mm:ss").fromNow()}
          </span>
          <UserGroupIcon
            className=" ml-3 h-4 w-4 text-brand-green-lighter3"
            aria-hidden="true"
          />
          <span className="pl-1 text-sm text-gray-500">
            {highlightSearchTerm(recording.participants.join(", "), searchTerm)}
          </span>
        </div>
        {recording.summarySafety !== 2 && (
          <div
            className="text-sm mt-1"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {summaryDisplay(recording, searchTerm)}
          </div>
        )}
      </div>
      <div className="ml-auto flex items-center">
        {shouldShowTrashIcon() && (
          <button
            className="p-4 bg-[#ededed] hover:bg-[#e3e3e3] focus:bg-[#dfdede] rounded-[24px]"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.openDeleteModal(props.recording);
            }}
          >
            <TrashIcon
              className="  h-4 w-4 text-brand-green-lighter3"
              // aria-hidden="true"
            />
          </button>
        )}
      </div>
    </a>
  );
};

export default ListViewRecording;

const summaryDisplay = (recording, searchTerm) => {
  if (recording.matchedTranscriptionItem && searchTerm) {
    return highlightSearchTerm(recording.matchedTranscriptionItem, searchTerm);
  }
  return recording.summary;
};

const highlightSearchTerm = (text, searchTerm) => {
  if (searchTerm) {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === searchTerm.toLowerCase()
                ? { fontWeight: "bold" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  }

  return text;
};
