import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  PlusIcon,
  CheckCircleIcon,
  CogIcon,
} from "@heroicons/react/outline";
import Avatar from "./Avatar";
import amplitude from "amplitude-js";

const ManageTeamModal = ({ isOpen, setIsOpen, user, setUser }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [error, setError] = useState(null);
  const [orgsToJoin, setOrgsToJoin] = useState([]);
  const [newOrgName, setNewOrgName] = useState("");
  const [usingEmailProvider, setUsingEmailProvider] = useState(false);
  const [showAllTeams, setShowAllTeams] = useState(false);

  useEffect(() => {
    if (isOpen && user) {
      if (user.orgID) {
        fetchAllMembers();
      } else {
        fetchAvailableOrgs();
      }
    }
  }, [isOpen, user]);

  const fetchAllMembers = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/orgs/members?include_pending=true`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTeamMembers(data.filter((member) => member.orgID === user.orgID));
        setInvitedMembers(data.filter((member) => member.orgID === null));
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const fetchAvailableOrgs = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/orgs/available?email=${user.email}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUsingEmailProvider(data.usingEmailProvider);
        setOrgsToJoin(data.orgs || []);
      }
    } catch (error) {
      console.error("Error fetching available orgs:", error);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    if (!newMemberEmail) {
      setError("Email is required");
      return;
    }

    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/invite-user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          orgID: user.orgID,
          userEmail: newMemberEmail,
        }),
      });

      if (response.ok) {
        setNewMemberEmail("");
        fetchAllMembers();
        setError(null);

        amplitude
          .getInstance()
          .logEvent("user invited to team from manage team modal");
      } else {
        const errorData = await response.text();
        setError(errorData);
      }
    } catch (error) {
      console.error("Error inviting user:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleJoinOrg = async (orgID) => {
    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/join`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          email: user.email,
          orgID: orgID,
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json();

        setUser(updatedUser);

        amplitude
          .getInstance()
          .logEvent("user joined team from manage team modal");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error joining org:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleCreateOrg = async (e) => {
    e.preventDefault();
    if (!newOrgName) {
      setError("Organization name is required");
      return;
    }

    try {
      const response = await fetch(`https://backend.scribbl.co/orgs`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          email: user.email,
          orgName: newOrgName,
          isPrivate: false,
          orgDomain: user.email.split("@")[1],
        }),
      });

      if (response.ok) {
        const newOrg = await response.json();
        setUser({ ...user, org: newOrg, orgID: newOrg.ID });
        setNewOrgName("");
        setError(null);

        amplitude
          .getInstance()
          .logEvent("user created team from manage team modal");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error creating org:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {user && user.orgID
                      ? `Manage ${user.org.name}`
                      : "Join or Create a Team"}
                  </Dialog.Title>

                  <div className="mt-4">
                    {user && user.orgID ? (
                      <>
                        <form onSubmit={handleInvite} className="space-y-4">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Invite team member
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="email"
                                value={newMemberEmail}
                                onChange={(e) =>
                                  setNewMemberEmail(e.target.value)
                                }
                                className="focus:ring-brand-green focus:border-brand-green flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                placeholder="Email address"
                              />
                              <button
                                type="submit"
                                className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-brand-green text-white text-sm"
                              >
                                <PlusIcon className="h-5 w-5 mr-1" /> Invite
                              </button>
                            </div>
                            {error && (
                              <p className="mt-1 text-sm text-red-600">
                                {error}
                              </p>
                            )}
                          </div>
                        </form>
                        <div className="mt-6 max-h-60 sm:max-h-96 overflow-y-auto">
                          <h4 className="text-sm font-medium text-gray-900 sticky top-0 bg-white py-2">
                            Team Members
                          </h4>
                          <ul className="divide-y divide-gray-200">
                            {teamMembers.map((member) => (
                              <li
                                key={member.ID}
                                className="py-2 flex justify-between items-center"
                              >
                                <div className="flex items-center">
                                  {member.picture ? (
                                    <img
                                      className="h-8 w-8 rounded-full"
                                      src={
                                        member.picture || "/default-avatar.png"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      email={member.email}
                                      name={member.name}
                                    />
                                  )}
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      {member.name}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {member.email}
                                    </p>
                                  </div>
                                </div>
                                <CheckCircleIcon className="h-5 w-5 text-green-500" />
                              </li>
                            ))}
                            {invitedMembers.map((member) => (
                              <li
                                key={member.ID}
                                className="py-2 flex justify-between items-center"
                              >
                                <div className="flex items-center">
                                  {member.picture ? (
                                    <img
                                      className="h-8 w-8 rounded-full"
                                      src={
                                        member.picture || "/default-avatar.png"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      email={member.email}
                                      name={member.name}
                                    />
                                  )}
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      {member.name}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {member.email}
                                    </p>
                                  </div>
                                </div>
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Team Invite Pending
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        {user && !user.orgID && (
                          <div className="text-center px-4 py-4 max-w-md mx-auto">
                            <div className="text-4xl mb-4">👥✨</div>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">
                              Welcome to Scribbl Teams!
                            </h3>
                            <p className="text-sm text-gray-600 mb-6">
                              Scribbl shines brightest with collaboration. Join
                              or create a team to get started.
                            </p>

                            <div className="space-y-6">
                              {orgsToJoin.length > 0 && (
                                <div className="bg-gray-50 p-4 rounded-lg">
                                  <h4 className="text-lg font-medium text-gray-900 mb-3">
                                    Available Teams to Join
                                  </h4>
                                  <div className="space-y-2">
                                    {orgsToJoin
                                      .slice(
                                        0,
                                        showAllTeams ? orgsToJoin.length : 3
                                      )
                                      .map((org) => (
                                        <div
                                          key={org.ID}
                                          className="flex justify-between items-center bg-white p-3 rounded-md shadow-sm"
                                        >
                                          <div>
                                            <p className="text-sm font-medium text-gray-900">
                                              {org.name}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                              {org.memberCount} members
                                            </p>
                                          </div>
                                          <button
                                            onClick={() =>
                                              handleJoinOrg(org.ID)
                                            }
                                            className="px-4 py-2 bg-brand-green text-white text-sm rounded-md hover:bg-brand-green-darker2 transition-colors duration-200"
                                          >
                                            Join
                                          </button>
                                        </div>
                                      ))}
                                  </div>
                                  {!showAllTeams && orgsToJoin.length > 3 && (
                                    <button
                                      onClick={() => setShowAllTeams(true)}
                                      className="text-sm text-brand-green hover:text-brand-green-darker2 mt-3 focus:outline-none"
                                    >
                                      Show {orgsToJoin.length - 3} more teams
                                    </button>
                                  )}
                                  {showAllTeams && orgsToJoin.length > 3 && (
                                    <button
                                      onClick={() => setShowAllTeams(false)}
                                      className="text-sm text-brand-green hover:text-brand-green-darker2 mt-3 focus:outline-none"
                                    >
                                      Show less
                                    </button>
                                  )}
                                </div>
                              )}

                              {orgsToJoin.length === 0 && (
                                <div className="bg-blue-50 rounded-lg p-3 mb-4 text-sm">
                                  <h4 className="font-medium text-blue-800 mb-1">
                                    Already working with a team?
                                  </h4>
                                  <p className="text-blue-700">
                                    Ask a colleague to invite you to their
                                    Scribbl workspace via team settings.
                                  </p>
                                </div>
                              )}

                              <div className="bg-gray-50 p-4 rounded-lg">
                                <h4 className="text-lg font-medium text-gray-900 mb-3">
                                  Or Create a New Team
                                </h4>
                                <form
                                  onSubmit={handleCreateOrg}
                                  className="space-y-3"
                                >
                                  <div>
                                    <label
                                      htmlFor="orgName"
                                      className="sr-only"
                                    >
                                      Team name
                                    </label>
                                    <input
                                      type="text"
                                      id="orgName"
                                      value={newOrgName}
                                      onChange={(e) =>
                                        setNewOrgName(e.target.value)
                                      }
                                      className="focus:ring-brand-green focus:border-brand-green block w-full rounded-md text-sm border-gray-300"
                                      placeholder="Enter your team name"
                                    />
                                  </div>
                                  <button
                                    type="submit"
                                    className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green transition-colors duration-200"
                                  >
                                    <PlusIcon className="h-5 w-5 mr-2" />
                                    Create Your Team
                                  </button>
                                </form>
                              </div>
                            </div>

                            {error && (
                              <p className="mt-4 text-sm text-red-600">
                                {error}
                              </p>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {user && user.orgID && (
                <div className="mt-6 pt-4 border-t border-gray-200">
                  <a
                    href={"/settings/team"}
                    className="block w-full text-left px-3 py-2 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors duration-200 flex items-center justify-between group"
                  >
                    <div className="flex items-center">
                      <CogIcon className="h-5 w-5 text-gray-400 group-hover:text-brand-green mr-3" />
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          Team Settings
                        </p>
                        <p className="text-xs text-gray-500">
                          Manage subscriptions and billing
                        </p>
                      </div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400 group-hover:text-brand-green"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ManageTeamModal;
